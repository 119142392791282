export const locale = {
  lang: 'en',
  data: {
    COMMON: {
      PRICE: 'Price',
      MONTH: 'month',
      NO: 'No',
      YES: 'Yes',
      HEADQUARTER: 'Headquarter',
        CATEGORY_TYPES: {
            TITLE: 'Category type',
            TV: 'TV',
            TELE: 'TELE',
            BROADBAND: 'Broadband',
        }
    },
    INFO: {
      SEARCH: 'Search',
      SEARCHING: 'Searching',
    },
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      CATALOG: 'Catalog',
      ORDERS: 'Orders',
      ORDERS_VIEW: 'View',
      ADMINISTRATION: 'ADMINISTRATION',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: "Don't have an account?",
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Log In',
      },
      LOGOUT: {
          BUTTON: 'Logout',
      },
      FORGOT: {
        TITLE: 'Enter your email',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.',
        SENT: 'Check your email',
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfully registered.',
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      },
      SERVER_ERRORS: {
        incorrect_password_or_email: 'Incorrect password or e-mail',
        ip_not_allowed: 'Your ip address isn`t allowed',
        wrong_password: 'wrong password',
        missing_user: 'missing user',
        too_many_requests: 'too many requests',
        invalid_credentials: 'Invalid credentials',
      },
    },
    USER: {
      HELLO: 'Hi',
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields',
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted',
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created',
        },
      },
    },
    PRICING_INPUT: {
      TITLE: 'Pricing input',
      PURCHASE_PRICE: 'Purchase price',
      SALES_PRICE: 'Sales price',
      ALL_VALUE_FILTER: 'All',
      CONTRACT_NAME: 'Contract name',
      TABLE: {
        PROVIDER_NAME: 'Provider name',
        NAME: 'Name',
        DISCOUNT: 'Discount',
        SUBVENTION: 'Subvention',
        IMEI_FIRST: 'IMEI 1',
        IMEI_SECOND: 'IMEI 2',
        IMEI_THIRD: 'IMEI 3',
        MANUAL_PRICE_PER_MONTH: 'Manual price per month',
        MANUAL_PRICE: 'Manual price',
        TO_PAY: 'To pay',
        COST: 'Cost',
        PRIORITY: 'Priority',
        MORE: 'More',
        MODIFY: 'Modify',
        SAVE: 'Save',
        CANCEL: 'Cancel',
        TOTAL_COMMISSION: 'Ersättning',
        PURCHASE_PRICE: 'Purchase price',
        TOTAL: 'Total',
      },
      ERRORS: {
        ALLOWED_INPUT: 'Allowed',
      },
    },
    CONTRACT_INPUT: {
      TITLE: 'Contract input',
      ARTICLE_FILTER: 'Article name filter',
      PROVIDER_NAME: 'Provider name',
      MONTH_LENGTH: 'Month length',
      ADD_NEW: 'Add new',
      TABLE: {
        MANUFACTURER: 'Manufacturer',
        ARTICLE_NAME: 'Article Name',
        MONTH: 'Month',
        DISCOUNT: 'Discount',
        MONTHLY_COST: 'Monthly Cost',
        DATA: 'data',
        FEE_IN_STORE: 'Fee in store',
        FEE_AMOUNT: 'Fee amount',
        FEE_ARTICLE: 'Fee article',
        STAFF_BONUS: 'Staff bonus',
        FOCUSED: 'Focused',
        MODIFY: 'Modify',
        EDIT: 'Edit',
      },
      EDIT_PAGE: {
        TITLE: 'Contract edit',
        SEARCH_INFO: {
          TITLE: 'Search info',
          PROVIDER_NAME: 'Provier name',
          CONTRACT_TYPE: 'Contract type',
          PRODUCT_SEARCH: 'Product search',
          UNDO: 'Undo',
          SAVE: 'Save',
        },
        PRODUCT_INFO: {
          TITLE: 'Product information',
          PRODUCT_GROUP: 'Product group',
          MANUFACTURER: 'Manufacturer',
          ARTICLE_NUMBER: 'Article number',
          ARTICLE_NAME: 'Article name',
          CONTRACT_LENGTH: 'Contract length',
          DISCOUNT: 'Discount',
          MONTHLY_COST: 'Monthly cost',
          DATA_INCLUDED: 'Data included',
          CONTRACT_TYPE: 'Contract type',
          EXTRA_SIM_FLAG: 'Extra sim flag',
          ONLINE_VISIBLE: 'Visible online',
        },
        CALCULATION_INFO: {
          TITLE: 'Calculation',
          TOTAL_COMMISSION: 'Total commission',
          PERCENT_COMMISSION: '% commission',
          CALCULATED_COMMISSION: 'Amount kept',
          CALCULATION_MODEL: 'Calculation model',
        },
        CONNECTED_ARTICLES: {
          TITLE: 'Connected articles',
          STARTING_FEE_IN_STORE: 'Starting fee in store',
          STARTING_FEE_AMOUNT: 'Starting fee amount',
          STARTING_FEE_ARTICLE: 'Starting fee article',
          ADDITIONAL_SUBVENTION: 'Additional subvention',
          SUBVENTION_COMMISSION: 'Subvention commission',
          SUBVENTION_ARTICLE: 'Subvention article',
        },
        INCENTIVES_INFO: {
          TITLE: 'Incentives',
          STAFF_BONUS: 'Staff bonus',
        },
      },
    },
    ORDER: {
      TITLE_LIST: 'Order list',
      TITLE_EDIT: 'Edit order',
      TITLE_NEW: 'Create new order',
      // New contract
      TITLE_TYPE: 'New contract',
      TITLE_COMMENT: 'Comments to order',
      TITLE_LOGGING: 'Logging change to order',
      BTN_CANCELLED: 'Cancel',
      BTN_RESET: 'Reset',
      BTN_BACK: 'Back',
      BTN_SAVE: 'Release',
      BTN_DOWNLOAD: 'Download',
      BTN_SAVE_COMMENT: 'Add',
      BTN_NEW: 'New',
      BTN_EDIT: 'Edit Order',
      BTN_DELETE: 'Delete Order',
      BTN_PREVIEW_INSURANCE_PROPOSAL: 'Preview insurance proposal',
      BTN_PREVIEW_INSURANCE_FINAL_DOCUMENT: 'Activated insurance agreement',
      INSURANCE_PROPOSAL_NOT_RECEIVED:
        'An insurance document has not been received yet',
      INSURANCE_FINAL_PROPOSAL_NOT_RECEIVED:
        'Final insurance document has not been received yet',
      CONTINUE_ORDER: 'Continue order',
      ORDER_UUID: 'Order uuid',
      TEMPORARY_ORDER_CREATED: 'Temporary order created',
      TEMPORARY_ORDER_UPDATED: 'Temporary order updated',
      HUNGARY: {
        NEXT_BUTTON: 'Next',
        BACK_BUTTON: 'Back',

        ORDER_VODAFONE_STATUS: 'Vodafone megrendelés állapota',
        BUDAPEST_BANK_STATUS: 'Budapesti banki megbízás állapota',
        AEGON_INSURACE_STATUS: 'Aegon biztosítási megrendelés állapota',

        SIM_ACTIVATION: {
          ENTER:
            'Please enter the SIM card number again to verify the activation of the SIM card',
          ERROR: 'Incorrect SIM card number',
        },

        STATUSES: {
          RECEIVED: 'received',
          REGISTRATION_IN_PROGRESS: 'registration in progress',
          REGISTRATION_APPROVED: 'registration approved',
          REGISTRATION_DECLINED: 'registration declined',
          INCORRECT_DETAILS: 'incorrect details',
          PENDING: 'pending',
          OFFER_RECEIVED: 'offer received',
          INSURANCE_APPROVED: 'insurance approved',
          INSURANCE_DECLINED: 'A insurance declined',
        },

        CURRENT_OPERATOR_TITLE_SHORT: 'Current service provider',
        CURRENT_OPERATOR_TITLE: 'Válassza ki a jelenlegi mobilszolgáltatóját',
        CURRENT_OPERATOR_NAME: 'Szolgáltató',
        CURRENT_OPERATOR_MONTHLY_AMOUNT: 'havi összeg',
        CURRENT_OPERATOR_CONTRACT_TYPE: 'Feltöltőkártyás Flotta Előfizetés',

        SUMMARY_BLOCK: {
          DEVICE: 'Készülék',
          TOTAL_PRICE: 'Teljes ár',
          PER_MONTH: 'Havonta',
          DEVICE_DISCOUNT: 'készülék kedvezmény',
          CONTRACT: 'Szerződés',
          FINANCE: 'Hitel',
          FINANCE_BLOCK: 'Installment payment',
          FINANCE_TO_PAY: 'Finance to pay',
          TOTAL_AMOUNT: 'Teljes összeg',
          FINANCE_TOTAL_AMOUNT: 'Amount to be financed',
          INSTALLMENT_PAYMENT: 'To be paid upon conclusion of the contract',
          INSURANCE: 'Insurance',
          TOTAL_21: 'Payable 21x per month',
          TOTAL_3: 'Amount to be paid 3x (after 21 months) per month',
          TOTAL_22: 'Payable 22x per month',
          TOTAL_2: 'Amount to be paid 2x (after 22 months) per month',
          SUBTOTAL: 'Összesen',
          SUBTOTAL_DEVICE: 'Fizetendő Vodafone előfizetéssel',
          SUBTOTAL_INSURANCE: 'Egyéb összesen',
          TO_PAY: 'Önerö',
          ACCESSORIES: 'Accessories',
          ADMINISTRATION_FEE: 'Administration fee',
        },

        INSURANCE_DOCUMENT: {
          TITLE: 'szerződés',
          TEXT: 'Before proceeding, please inform the customer about the data management information and, if required, provide it in printed form. If the customer agrees to manage the data, click on The "Next" button.',
          NEXT: 'Következő',
          AGREEMENT:
            'A Media Markt, a Vodafone, a Biztosító és a Budapest Bank együttes elhatározása, hogy a Media Markt-nál történő készülék vásárlás esetén a Önnek lehetőséget biztosítsanak arra, hogy a készülékvásárlással egyidejűleg, illetve azt követően Kapcsolódó szolgáltatásokat is igénybe tudjon venni.\n' +
            '\n' +
            'A Media Markt-nál történő készülék vásárlás- és Kapcsolódó szolgáltatás igénylés esetén az Ön személyes adatai a Media Markt Verticula programjában kerülnek rögzítésre, majd innen a kiválasztott szolgáltatást nyújtó adatkezelő részére továbbításra, így Önnek nem szükséges több helyszínen, többször megadnia személyes adatait, mindez egy helyen és időben megvalósítható.\n' +
            '\n' +
            'Mindezekre tekintettel a Media Markt és az igénybe vett Kapcsolódó szolgáltatások nyújtói a Verticula programban kezelt adatok tekintetében közös adatkezelőknek minősülnek. Fontos, hogy személyes adataihoz minden esetben csak azon adatkezelők férnek hozzá, akiknek Kapcsolódó szolgáltatását igénybe kívánja venni, és minden adatkezelő csak azon adataihoz fér hozzá, amelyek az általa nyújtott szolgáltatás nyújtásához elengedhetetlenül szükségesek. Az egyes szolgáltatókkal történő szerződéskötés során a Szolgáltatók külön tájékoztatást nyújtanak Önnek a Verticula programon túli adatkezelésről, mely már kifejezetten a Szolgáltatóval fennálló jogviszonyára vonatkozó adatkezelésre tér ki.\n' +
            '\n' +
            'A teljeskörű tájékoztatást a közös adatkezelésről szóló tájékoztatónkban olvashatja, melyet kérése esetén máris nyomtatunk.',
        },

        ACTIVITY_MENU: {
          TITLE: 'Automatic logout warning.',
          TEXT_ONE: 'Check out in 10 minutes',
          TEXT_TWO: 'Due to inactivity. Do you want to stay logged in?',
          TEXT_THREE: 'Press "Stay logged in" to continue',
          STAY: 'Stay logged in',
          LOG_OUT: 'Logout',
        },

        INFOVIDEO: {
          BACK_BUTTON: 'Back',
          NEXT_BUTTON: 'Next',
          CONFIRM_BUTTON: 'Confirm',
          ORDER_TITLE: 'Ordinary summary',
          ORDER_TEXT_HEADER: 'Infotide video:',
          ORDER_TEXT:
            'Kedves ügyfelünk köszönjük, hogy meghallgattad Kollégánkat, az elöbbi összefoglaló oldalon összegeztük neked azt amiről beszéltetek.A következő kb X percben szeretnénk tájékoztatni minden olyan feltételről téged, ami elengedhetetlen ahhoz, hogy szerződést kössünk egymással. Célunk, hogy minden szükséges feltételt megismerj a lehető legegyszerűbb módon. Ehhez nyújunk segítséget a következő rövid videókkal. Minden video egy nyilatkozat megismerését teszi lehetővé, így videónként szükséges döntened majd. Lesznek olyanok ahol dönthesz úgy, hogy igénybe kívánod-e venni az adott lehetőséget, azonban az itt egy piktogram) ilyen jelölésselellátott videók esetében az IGEN válasz jelölése nélkül a szerződés nem köthető meg, így, ha a videó megtekintését követően további kérdésed merülne fel ezzel a gombbal (piktogramm) ismét megtekintheted azt. Ha a nyilatkozat elolvasását követően mégis kérdésed maratt fordulj bátran kollégáinkhoz."',
          VIDEO_TITLES: [
            '1. Infovideo, mint digitális segítség',
            '2. Csomagajánlatunk (N/A)',
            '3. Választott Vodafone előfizetés (N/A)',
            '4. Magánszemélyként szerződhetek',
            '5. Szükséges dokumentumok',
            '6. Roaming',
            '7. Szerződésmásolat',
            '8. Elektronikus értesítések',
            '9. Felmondási jog',
            '10. Adatvédelmi tájékoztató',
            '11. Hívásrészletező, személyes adatok',
            '12. Egyedi értékhatár',
            '13. Nyilatkozat teljesköru tajekoztasrol',
            '14. Szerződés modositas',
            '15. Szerződés idötartamok',
            '16. Hívásrészletező és tudakozó',
            '17. Direkt marketing hozzájárulás',
            '18. Helymeghatározási adatok',
          ],
          UNDER_VIDEO_TEXTS: [
            'Nyilatkozom, hogy az egyedi Előfizetői Szerződésben foglaltakat - különösen az abban foglalt hivatkozásokat - megismertem és tudomásul veszem. Tudomásul veszem, hogy az Előfizetői Szerződés az egyedi előfizetői szerződésből és az ÁSZF-ből áll, a szolgáltatással kapcsolatos kérdésekben elsősorban az egyedi előfizetői rendelkezések, másodsorban az ÁSZF rendelkezései az irányadóak. Nyilatkozom, hogy az Általános szerződési feltételeket (ÁSZF) megismertem és tudomásul veszem, hogy az az Előfizetői Szerződés részét képezi. Nyilatkozom, hogy az Egyéb Szolgáltatások Szerződési Feltételeit (ESZSZF) megismertem és tudomásul veszem, hogy az az Előfizetői Szerződés részét képezi.',
            'Nyilatkozom, hogy az Előfizetői Szerződés megkötése során a Szolgáltató teljes körű tájékoztatást nyújtott az érvényes barangolási díjakról és a szabályozott barangolásos adatátviteli szolgáltatások díjazására vonatkozóan',
            'Nyilatkozom, hogy az Előfizetői Szerződés megkötése előtt megkaptam a Szolgáltatótól a tájékoztatást arról, hogy az Előfizetői Szerződés fennállása alatt, vagy annak megszűnését követően az Eht. 143. § (2) bekezdése szerinti elévülési időn belül évente egyszer ingyenesen kérhetem az Előfizetői Szerződés általam megjelölt időpontjában hatályos tartalmáról szóló, legalább az Eszr. 11. § (1) bekezdés szerinti elemeket tartalmazó dokumentum átadását.',
            'Nyilatkozom, hogy a Szolgáltató értesítési kötelezettségének - beleértve a felmondást is - az általam megadott elérhetőségeken az Eht. 144. § (4) bekezdés a)-c) pontjában foglaltaknak megfelelően elektronikus úton vagy az elektronikus hírközlési szolgáltatás jellegéhez igazodó módon (különösen: SMS, MMS, telefonhívás) is eleget tehet.',
            'Tájékoztatást megkaptam, hogy az előfizetői szerződésben megjelölt igénybevételi helyen a szolgáltatás minőségének hiányosságaival kapcsolatos felmondási jog gyakorlásának lehetőségéről, feltételeiről és következményeiről az ÁSZF 12.4.2.1(d) és 12.6.9 pontban foglaltak alapján az alábbiak szerint: Az ÁSZF 12.4.2.1. (d) rendelkezése jelenleg: "Vezeték nélküli internet (azaz mobil internet hozzáférési), valamint mobil radiotelefon szolgáltatásra kötött Előfizetői Szerződés esetén Előfizető a szolgáltatás nyújtásának megkezdésétől számított 14 (tizennégy) napon belül a lenti 12.6.9. pontban meghatározott jogkövetkezményekkel azonnali hatállyal felmondhatja, ha az Előfizetői Szerződés további teljesítéséhez fűződő érdeke azon okból szűnt meg, hogy az Előfizetői Szerződésben megjelölt igénybevételi helyen (cím, annak hiányában helyrajzi szám) a Szolgáltatás minősége nem felel meg az Előfizetői Szerződésben foglaltaknak, vagy a Szolgáltatás nem vehető igénybe. A felmondási jog gyakorlásának lehetőségéről, feltételeiről és következményeiről Szolgáltató köteles igazolható módon tájékoztatni az Előfizetőt az Előfizetői Szerződés megkötésével egyidejűleg." Az ÁSZF 12.6.9 rendelkezése jelenleg: "A 12.4.2.1. (d) pontban foglalt felmondás esetén a Szolgáltató csak a szerződéskötéstől a felmondásig eltelt időre járó arányos havidíist valamint fordolomarínunc díiat tartalmazó Coldáltatác acatáhon 27 Flőfizető által tánvlodocon folhasznált fordalom díiát vadu a',
            'Előfizető kijelentem, hogy a Szolgáltatótól az Eht. 159. (1) bekezdésében foglaltakra vonatkozó adatvédelmi tájékoztatást megkaptam, erről részletes információt a ÁSZF 3. számú melléklete tartalmaz.',
            'Tudomásul veszem, hogy a Szolgáltató a 2/2015. (III. 30.) NMHH rendeletben előírt kötelezettségének eleget téve felhívta figyelmemet arra, hogy a részletes számlainformációt tartalmazó kimutatással más felhasználók személyes adatainak birtokába is juthatok. Ezeknek megismerésére csak akkor vagyok jogosult, ha a készüléket kizárólag én használom vagy telefonkészülékem használói - a tájékoztatásukat követően - felém tett nyilatkozattal ehhez hozzájárultak.',
            'Tudomásul veszem, hogy a Szolgáltató a szolgáltatás tekintetében az igénybevétel korlátjaként összeghatárokat, vagy hívás-, üzenet-, adatmennyiséget határozott meg, és ezek túllépése esetén az Eht 137. § (1) bekezdés d) pontja szerint korlátozással élhet.',
          ],
          INFOVIDEO_LOGIN_TITLE: 'Infovideo Login',
          COMPLETED_ALL_STEPS:
            'All your statements have been recorded! Thank You!',
        },

        // First step

        FIRST_TITLE: 'Preliminary data',
        STORE_CODE: 'merchantCode',
        STORE_NAME: 'merchantName',
        FINANCE_ADMIN_CODE: 'creditManagerCode',
        MARKER: 'markerData',
        CREDIT_TYPE: 'creditType',
        CONFIRMATION: 'forApproval',
        CMBET: 'homeLandlinePhone',
        CMBSZZ: 'bankAccountCertifiedIncome',
        CMBB: 'bBbankAccountCertifiedIncome',
        CMBMNJ: 'codesPageCMBMNJifDoNotContribute',
        BNPL: 'bnpl',
        CUSTOMER_TYPE_NAME: 'customerTypeName',
        CREDIT_INTERMEDIARY_AGENT: 'creditIntermediaryAgent',
        CUSTOMER_WAITING_FOR_REVIEW: 'customerWaitingForReview',
        DOES_CLIENT_ACT_ON_ITS_OWN_BEHALF: 'doesClientActOnItsOwnBehalf',

        // Second step

        SECOND_TITLE: 'Statement details',
        HAVE_THE_PUBLIC_CHARGES_BEEN_PAID:
          'haveThePublicChargesBeenPaidOnYourIncome',
        ADD_INCOME: 'Add income',
        TYPE_OF_INCOME: 'typeOfIncome',
        METHOD_OF_PROOF_OF_INCOME: 'methodOfProofofIncome',
        DATE_OF_ISSUE_CERTIFICATE: 'dateOfIssueOfCertificateOfIncome',
        AMOUNT_OF_MONTHLY_NET_INCOME: 'amount Of Monthly Net Income',
        CHANGE_COLUMN: 'Edit',
        MODIFY_BUTTON: 'Modify',
        REMOVE_BUTTON: 'Remove',
        ARE_YOU_GUARANTOR:
          'are You Guarantor In Financial Lease Taken By Your Relative',
        AMOUNT_OF_MONTHLY_LEASE:
          'amount OfMonthlyLeasePaymentNotExpireWithin30Days',
        HAS_LOAN_COMBINED_WITH_HOME: 'hasLoanCombinedWithHomeSavingsContract',
        AMOUNT_OF_MONTHLY_FEE_ARISING:
          'amount OfMonthlyFeeArisingFromAnLtpContract',
        HAS_LOAN_COMBINED_WITH_LIFE: 'has LoanCombinedWithLifeInsurance',
        AMOUNT_OF_MONTHLY_PREMIUM_ARISING:
          'amount OfMonthlyPremiumArisingFromTheLifeInsuranceContract',
        CLAIMING_TOTAL_MONTHLY_NET_INCOME: 'claiming TotalMonthlyNetIncome',
        NET_MONTHLY_INCOME_OF_CLAIMING_HOUSEHOLD:
          'net MonthlyIncomeOfClaimingHousehold',
        TOTAL_INCOME: 'totalIncome',

        // Third step

        THIRD_TITLE: 'Személyes adatok',
        ID_CARD_NUMBER: 'idCardNumber',
        IDENTITY_CARD_TYPE: 'indentityCardType',
        DATE_OF_ISSUE: 'dateOfIssue',
        GENDER: 'gender',
        COUNTRY_OF_CITIZENSHIP: 'countryOfCitizenship',
        TITLE: 'title',
        FIRST_NAME: 'firstname',
        LAST_NAME: 'lastname',
        BIRTH_FIRST_NAME: 'birthFirstname',
        BIRTH_LAST_NAME: 'birthLastname',
        MOTHERS_BIRTH_FIRST_NAME: 'mothersBirthFirstname',
        MOTHERS_BIRTH_LAST_NAME: 'mothersBirthLastname',
        DATE_OF_BIRTH: 'birthDate',
        PLACE_OF_BIRTH: 'placeOfBirth',
        DOES_CUSTOMER_CONSENT_TO_THE_BANK:
          'doesCustomerConsentToTheBankRequestingItsPositiveDataInConnectionWithCreditAssessment',
        DOES_CUSTOMER_CONSENT_TO_ANOTHER_BANK:
          'doesCustomerConsentToAnotherBankTakingOverTheirDataFromPositiveCCIS',
        DOES_CLIENT_CONSENT_THE_POSITIVE:
          'doesClientConsentThePositiveKHRBeingAbleProcessHisDataFor5yearsAfterTerminationOfContract',
        PLACE_OF_DECLARATION: 'placeOfDeclaration',
        DATE_OF_DECLARATION: 'dateOfDeclaration',
        MARTIAL_STATUS: 'martialStatus',
        EDUCATION: 'education',
        OCCUPATION_TYPE: 'occupationType',
        NUMBER_OF_SEARCH_ENGINES: 'numberOfSearchEngines',
        NUMBER_OF_DEPENDENTS: 'numberOfDependents',

        // Fourth step

        FOURTH_TITLE: 'Lakcim adatok',
        PERMANENT_ZIP: 'Permanent address / zip code',
        PERMANENT_CITY: 'Permanent address / city',
        PERMANENT_NAME_OF_PUBLIC_AREA:
          'Permanent address / name of public area',
        PERMANENT_NAME_OF_PUBLIC_SPACE:
          'Permanent address / name of public space',
        PERMANENT_HOUSE_NUMBER: 'Permanent address / house number',
        PERMANENT_BUILDING: 'Permanent address / building',
        PERMANENT_STAIRCASE: 'Permanent address / staircase',
        PERMANENT_FLOOR: 'Permanent address / floor',
        PERMANENT_DOOR: 'Permanent address / door',

        RESIDENCE_ZIP: 'Residence address / zip code',
        RESIDENCE_CITY: 'Residence address / city',
        RESIDENCE_NAME_OF_PUBLIC_AREA:
          'Residence address / name of public area',
        RESIDENCE_NAME_OF_PUBLIC_SPACE:
          'Residence address / name of public space',
        RESIDENCE_HOUSE_NUMBER: 'Residence address / house number',
        RESIDENCE_BUILDING: 'Residence address / building',
        RESIDENCE_STAIRCASE: 'Residence address / staircase',
        RESIDENCE_FLOOR: 'Residence address / floor',
        RESIDENCE_DOOR: 'Residence address / door',
        RESIDENCE_PHONE_NUMBER: 'Residence address / phone number',
        RESIDENCE_PO_BOX: 'Residence address / PO BOX',

        COPY_FROM_PERMANENT_BUTTON: 'Copy from permanent',

        PHONE_NUMBER: 'phoneNumber',
        LANDLINE_PHONE_NUMBER: 'Landline phone number',
        PHONE_FOR_SHOPPING: 'mobilePhoneNumberForOnlineShopping',
        INTERNETBAN_NUMBER: 'internetPhoneNumber',
        MOBILLBANK_NUMBER: 'mobileBankingPhoneNumber',
        MOBIL_PHONE_NUMBER: 'mobilePhoneNumber',
        EMAIL: 'Email',
        TYPE_OF_MOBILE_CONTRACT: 'typeOfMobileContract',
        WHEN_DID_CONTRACT_WITH_MOBILE:
          'whenDidContractWithMobileServiceProvider',
        HOW_LONG_CURRENT_ADDRESS: 'howLongHaveYouLivedAtYourCurrentAddress',
        HOW_LONG_PROPERTY: 'howLongHaveYouLivedAtProperty',

        // Fifth step

        FIFTH_TITLE: 'Kártya adatok',
        CARD_NAME: 'nameCard',
        PASSWORD: 'Jelszó',
        NEED_MOBILE_BANK: 'needMobileBank',
        COBRAND_ID: 'cobrandId',

        // Sixth step

        SIXTH_TITLE: 'Munkáltató adatok',
        SELF_EPLOYEED: 'selfEmployeed',
        BUSINESS_CARD_NUMBER: 'businessCardNumber',
        UJKM_CODE: 'codesPageUJKMCode',
        POSITION: 'position',
        EMPLOYER: 'employer',
        CURRENT_JOB: {
          COMPANY_NAME: 'Current job / Company name',
          COMPANY_REGISTRATION_NUMBER:
            'Current job / company registration number',
          TAX_NUMBER: 'Current job / tax number',
          POSTCODE: 'Current job / postcode',
          CITY: 'Current job / City',
          PUBLIC_AREA: 'Current job / Public area',
          PUBLIC_SPACE: 'Current job / Public space',
          HOUSE_NUMBER: 'Current job / house number',
          BUILDING: 'Current job / building',
          STAIRCASE: 'Current job / staircase',
          FLOOR: 'Current job / floor',
          DOOR: 'Current job / Door',
          PHONE_NUMBER: 'Current job / phone number',
          EMPLOYER_EMAIL_ADDRESS: 'employer email address',
          SECTOR: 'Current job / Sector',
          OCCUPATION: 'Current job / Sector',
          WORK_PHONE_NUMBER: 'Current job / Work phone number',
          SIDE: 'Current job / side',
          BEGINNING_OF_EMPLOYMENT: 'Beginning of employment',
          END_OF_EMPLOYMENT: 'end of employment',
          FIXED_EMPLOYMENT: 'fixed employemnt',
        },
        PREVIOUS_JOB: {
          COMPANY_NAME: 'Previous job / company name',
          POSTCODE: 'Previous job / postcode',
          CITY: 'Previous job / city',
          PUBLIC_AREA: 'Previous job / public area',
          PUBLIC_SPACE: 'Previous job / public space',
          HOUSE_NUMBER: 'Previous job / house number',
          BUILDING: 'Previous job / building',
          STAIRCASE: 'Previous job / staircase',
          FLOOR: 'Previous job / floor',
          DOOR: 'Previous job / door',
          PHONE_NUMBER: 'Previous job / phone number',
          BEGINNING_OF_EMPLOYMENT: 'biginning of employment',
          END_OF_EMPLOYMENT: 'end of employment',
        },
        NAME_OF_CONTACT_PERSON: 'nameOfContactPerson',
        NUMBER_PHONE: 'phoneOfContactPerson',
        ATTACHMENT: 'attachment',
        EMPLOYER_PAYMENT_ACCOUNT_NUMBER: 'employerPaymentAccountNumber',
        PRIMARY_PRODUCER_CARD_NUMBER: 'primaryProducerCardNumber',

        // Seventh step

        SEVENTH_TITLE: 'Biztosito',
        TYPE_OF_INSURANCE: 'typeOfInsurance',
        NEEDS_THE_INCURANCE: 'needsTheInsurance',
        CONSTRUCTION: 'construction',
        DESIGNATION: 'designation',
        OTHER_GROUNDS_FOR_REFUSAL: 'otherGroundsForRefusal',

        // Eigth step

        EIGHT_TITLE: 'Tranzactio',
        MATURITY: 'maturity',
        MY_STRENGTH: 'myStrength',
        PURCHASE_PRICE: 'purchasePrice',
        LOAN_AMOUNT: 'loanAmount',
        BANK_ACCOUNT_NUMBER: 'bankAccountNumber',
        PRICE_WARRANTY_PRODUCTS: 'priceOfWarrantyProducts',
        CREDIT_LINE_REQUIRED: 'creditLineRequired',
        PAYMENT: 'payment',
        INSTALLMENT: 'installment',
        TRANSACTION_ID: 'transactionId',
        TYPE_OF_TRANSACTION: 'typeOfTransaction',
        CUSTOMER_TYPE_CODE: 'customerTypeCode',
        TRANSACTION_CUSTOMER_TYPE_CODE: 'transactionCustomerTypeCode',
        VOUCHER_AMOUNT: 'voucherAmount',
        PRODUCT_LIST_NAME: 'Product list / name',
        PIECE: 'piece',
        UNIT_PRICE: 'unit price',
        EAN: 'EAN code',
        AGREEMENT: {
          DOWNLOAD: 'Download',
          PRINT: 'Print',
          LOADING_ERROR: 'If you do not see the document, try opening it again',
        },
        NO_COMMENT: 'No comment',
      },
      REPORT: {
        DOWNLOAD_REPORT: 'Download report',
        FILTER: 'filter',
        SALES_PERSON_ID: 'Sales person id',
        DATE: 'Date',
        OPERATOR: 'Operator',
        TABLE: {
          OPERATOR: 'Operator',
          DATE: 'Date',
          STORE_ID: 'Varuhus ID',
          SALES_PERSON_ID: 'Sales Person ID',
          ORDER_NUMBER: 'Order Number',
          CONTRACT: 'Contract',
          PHONE: 'Phone',
          TOTAL_COMMISSION: 'Total Commission',
          TOTAL_PURCHASE: 'Total purchase',
          TOTAL_TO_PAY: 'Total to pay',
          GROSS_PROFIT: 'Gross profit',
          BONUS: 'Bonus',
          TOTAL: 'Total',
        },
      },
      FORM: {
        PERMANENT_ADDRESS: 'Permanent address',
        RESIDENCE_ADDRESS: 'Residence address',
        STATE: 'State',
        CUSTOMERID: 'Customer Id',
        PHONENUMBER: 'Phone number',
        ADDRESSCARDNUMBER: 'Address Card Number',
        BIRTHDATE: 'Birth date',
        FIRSTNAME: 'First name',
        LASTNAME: 'Last name',
        ADDRESS: 'Address',
        ZIPCODE: 'Zip Code',
        CITY: 'City',
        EMAIL: 'Email',
        ACCOUNTBANK: 'Account bank',
        BANKNAME: 'Bank name',
        IBAN: 'IBAN',
        CANCELLATIONREASON: 'Cancellation reason',
        DEVICE: 'Device',
        SUBVENTION: 'Subvention',
        CONTRACT: 'Contract',
        MONTHLY_COST: 'Monthly cost',
        MONTHLY_FEE: 'Monthly fee',
        COMMENT: 'Comment',
        SUBSCRIPTION_PHONE_NUMBER: 'Subscription phone number',
        ACTIVATION_DATE: 'Activation date',
        SUBSCRIPTION_NUMBER: 'Subscription number',
        IMEI_NUMBER: 'IMEI number',
        SIM_NUMBER: 'Sim card',
        CUSTOMER_CODE: 'Customer code',
        SALES_PRICE: 'Sales price',
        ACCESSORY: 'Accessory',
        GENDER: 'Gender',
        //
        MOTHERS_BIRTH_NAME: 'Mothers birth name',
        BIRTH_NAME: 'Birth name',
        PLACE_OF_BIRTH: 'Place of birth',
        COUNTRY_OF_CITIZENSHIP: 'Country of citizenship',
        IDENTITY_CARD_TYPE: 'Indentity card type',
        HOUSE_NUMBER: 'House number',
        NAME_OF_PUBLIC_AREA: 'Name of public area',
        NAME_OF_PUBLIC_SPACE: 'Name of public space',
        BUILDING: 'Building',
        STAIRCASE: 'Staircase',
        ID_CARD_NUMBER: 'Id card number (CAPITAL LETTERS and numbers)',
        FLOOR: 'Floor',
        DOOR: 'Door',
        RESIDENCE_CARD: 'Residence card (CAPITAL LETTERS and numbers)',
        MANUFACTURER_WARRANTY_TIME: 'Manufacturer warranty time (month)',
        ADDRESS_DETAILS: 'Address details',
        LINK_OPERATOR: 'Link to operator',
        SUMMARY_BONUS_AMOUNT: 'Summary bonus amount',
        SUMMARY_TO_PAY: 'Summary to pay',
        MONTHLY_DISCOUNT: 'Mothly discount',
        IS_EMPLOYED: 'Is employed?',
        CONTRACT_PHONE_NUMBER: 'Contract',
        SHOW_USE_ADDRESS_CHECKBOX: 'Use residence address',
        COPY_FROM_PERMANENT_ADDRESS: 'Copy from permanent address',
        VIEW: 'View',
        INSURANCE: 'Insurance',
        SCREENPROTECTOR: 'Screen Protector',
        NEW_PHONE_NUMBER: 'New phone number',
        ADDITIONAL_PRODUCT: 'Additional product',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
        FORBIDDEN_SYMBOLS_FIELD: 'Field contains forbidden characters',
        CONTRACT_PHONE_NUMBER_TO_BE_FILLEDOUT: 'Contract to be filled out',
        VODAFONE_STATUS_SELECT_REASON: 'Please, select decline reason.',
      },
      LIST_TABLE: {
        TITLE: 'Orders',
        ORDER_NUMBER: 'Order number',
        OPERATOR: {
          TITLE: 'Operator',
        },
        SALES: {
          TITLE: 'Sales person id',
        },
        PROVIDER_ORDER_ID: {
          TITLE: 'Provider order ID',
        },
        STORE_ID: {
          TITLE: 'Store ID',
        },
        DATE: {
          TITLE: 'Date',
        },
        GRABBED_BY: {
          TITLE: 'Grabbed by',
        },
        STATUS: {
          TITLE: 'Status',
          ACTIVE: 'You can edit',
          GRABBED: 'Edited by user',
          HOT: 'More 72 hours',
        },
        STATE: {
          TITLE_FILTER: 'Filter state',
          TITLE: 'State',
          ALL: {
            FULL: 'All Order',
            SHORT: 'All',
          },
          NEW: {
            FULL: 'New Order',
            SHORT: 'New',
          },
          PENDING: {
            FULL: 'Order pending',
            SHORT: 'Pending',
          },
          COMPLETED: {
            FULL: 'Completed Order',
            SHORT: 'Completed',
          },
          CANCELLED: {
            FULL: 'Cancelled Order',
            SHORT: 'Cancelled',
          },
          RECEIVED: {
            FULL: 'Order received (Awaiting credit check)',
            SHORT: 'Received',
          },
          CREDIT_CHECK_OK: {
            FULL: 'Credit check OK (Awaiting signature)',
            SHORT: 'Awaiting signature',
          },
          READY_FOR_SHIPMENT: {
            FULL: 'Ready for shipment (Closed)',
            SHORT: 'Closed',
          },
          PROPOSAL: {
            FULL: 'Proposal',
            SHORT: 'Proposal',
          },
          INFO_VIDEO: {
            FULL: 'Info vídeos in progress',
            SHORT: 'In Progress',
          },
        },
        FIRST_NAME: 'First name',
        LAST_NAME: 'Last name',
        PHONE_NUMBER: 'Phone number',
        EMAIL: 'Email',
        STORE: 'Store',
        HEADQUARTER: 'Headquarter',
        ACTIONS: {
          TITLE: 'Actions',
          BTN_EDIT: 'Grab Order',
          BTN_DELETE: 'Delete Order',
        },
      },
      LOG_TABLE: {
        NUMBER: 'Number',
        ACTOR: 'Actor',
        CHANGES: {
          TITLE: 'Changes',
          CARD_KEY: {
            OLD: 'Old',
            NEW: 'New',
          },
        },
        DATE_CHANGES: ' Date Changes ',
        INSURANCESTATUS: 'Insurance status',
        CONTRACTPHONENUMBER: 'Contract phone number',
        OPERATORSTATUS: 'Operator status',
        OPERATORREJECTIONREASON: 'Operator rejection reason',
      },
      ORDER_DIALOG: {
        BTN_CANCEL: 'Cancel order',
        BTN_NOT_CANCEL: 'Don\'t cancel',
        BTN_CLOSE: 'Cancel',
        BTN_SAVE: 'Save',
        REASON_CLOSING: {
          TITLE: 'Pls add brief explanation',
          COMMENT: 'Please enter Comment',
        },
        CONTRACT_DIALOG: {
          TITLE: 'Edit contract',
        },
        PHONE_DIALOG: {
          TITLE: 'Edit device',
          INPUT: 'Please enter device Name ',
        },
        ENTER_VENDO_ID: 'Enter your Vendo user id',
        CHOOSE_STORE: 'Which store are you working in?',
        CONFIRM_STORE: 'Confirm store',
      },
      DISCOUNT_CONFIRM: {
        TITLE: 'Discount confirmation',
        TEXT: 'Do you want to give',
        DISCOUNT: 'discount?'
      },
    },
    DASHBOARD: {
      TITLE: 'Dashboard',
      FILTERS: {
        HEADQUARTER: 'Headquarter',
        START_DATE: 'Start date',
        END_DATE: 'End date',
        PROVIDER: 'Provider',
        STORE: 'Store',
        EMPLOYEE: 'Employee',
      }
    },
    CATALOG: {
      CURRENCY: 'kr',
      SEARCH_DEVICE: 'Search device',
      SEARCH_PHONE: 'Search phone',
      CLICK_OPERATOR_CALL:
        'Click on operator when selling only subscriptions without telephone',
      DETAILS_PHONE: {
        CONTRACT: {
          TITLE: 'Contract',
          TITLE_EDIT: 'Edit Contract',
          TYPE_CONTRACT: {
            NEW: 'New contract',
            EXTENSION: 'Contract Extension',
          },
          DURATION_CONTRACT: {
            MONTH: 'month',
            oneYear: 'One year',
            twoYears: 'Two years',
            threeYears: 'Three years',
          },
          TABLE: {
            TITLE_NAME: 'Contract name',
            MONTHLY_COST_CONTRACT: 'Contract monthly cost',
            MONTHLY_COST_DEVICE: 'Device monthly cost',
            MONTHLY_COST_TOTAL: 'Total monthly cost',
          },
        },
        SUMMARY_BLOCK: {
          MODIFY: 'Ändra',
          PHONE: 'Phone',
          PAY_NOW: 'pay now',
          MONTHS: 'Months',
          MONTH: 'month',
          CONTRACT: 'Contract',
          ADD_ON: 'Add on',
          TO_PAY: 'To pay',
          TYPE_SUM_TO_PAY: 'Type sum to pay',
          ONE_TIME_FEE: 'One time fee',
          TOTAL_MONTHLY_COST: 'Total monthly cost',
          MINIMUM_TOTAL_COST:
            'Minimum total cost during {{contractLength}} months',
            TELIA: {
              EXPLANATION: 'Effective interest rate (the total cost for partial payment)' +
                  ' with a representative example from 2020-12-01: Utilized account credit of SEK 10,000 to 0% credit interest (tied)' +
                  ' with a repayment period of 24 months, with 24 installments of SEK 416.66 / month gives an effective interest rate' +
                  ' of a total of 0% with e-invoice / e-mail invoice (SEK 0) or 11.15% with paper invoice (SEK 49 / pc). The total repayment ' +
                  'for an account credit of SEK 10,000 is thus SEK 10,000 if you have a digital invoice and SEK 11,127 if you have a paper invoice.'
            }
        },
        DATA_USAGE: {
          TITLE: 'Data Usage (optional)',
          CALC: {
            TITLE: 'Calculator',
            video_streaming: 'Video streaming',
            social_media: 'Social Media',
            music_streaming: 'Music streaming',
            web_surfing: 'Web surfing',
            HOURS_PER_DAY: 'hours per day',
          },
          SIMPLE: {
            TITLE: 'Simple',
            SELECT_UNLIMITED: 'Unlimited',
          },
          TOTAL_DATA: 'Total Data',
          UNLIMITED: 'Unlimited',
        },
        device: {},
        PROVIDER: {
          TITLE: 'Provider',
          SORT_BY: {
            TITLE: 'Sort by',
            NAME: 'Name provider',
            PRICE: 'Price',
            GB: 'GB',
          },
          SELECT: 'Select',
          SELECTED: 'Selected',
          MORE_INFO: 'More info',
          SHOW_OPTIONS: 'Show options',
          HIDE_OPTIONS: 'Hide options',
          MONTHS_COMMITMENT: ' months commitment',
          NO_COMMITMENT: 'No commitment',
        },
        CARD: {
          TITLE: 'Card',
          DISCOUNT: 'Discount',
          TO_PAY: 'To pay',
          INSTALMENT_FEE: 'Instalment fee',
          TO_PAY_OWN_RESOURCES: 'To pay of own resources',
          TOTAL_PAY: 'Total to pay',
          TYPE_SUM_TO_PAY: 'Type sum to pay',
          MAX_TO_PAY_ERROR: 'Max sum to pay: {{toPay}}',
          CHECKOUT: 'Checkout',
          ONE_MORE: 'Add one more',
          REMOVE_DEVICE: 'Remove',
          MONTHLY_DISCOUNT: 'Monthly discount',
          NO_FINANCE: 'No financing',
          ADDITIONAL_INFO: 'Additional info',
          MARGIN: 'Margin'
        },
        ADDITIONAL_DEVICE: {
          ADD_SUB_DEVICE: 'Add Sub Device',
        },
        CUSTOM_SALES_DISCOUNT: {
          ERR_MSG_PERCENT: 'Only the percent from 0 to 100',
          ERR_MSG_AMOUNT: 'Only the number not bigger than the device price',
        }
      },
      CHECKOUT: {
        TITLE: 'Checkout',
        TITLE_DONE: 'Order completed',
        BACK: 'Back',
        NEXT: 'Continue',
        SAVE_ORDER_PROPOSAL: 'Save order proposal',
        FINISH_CHECKOUT: 'Finish order',
        ADD_TO_CART: 'Add to cart',
        GET_PERSONAL_DATA: 'Get information',
        IDENTIFY_WITH_BANKID: 'Identify with BankID',
        MODIFY_PERSONAL_DATA: 'Modify',
        SAVE_PERSONAL_DATA: 'Save',
        CONTRACT_TITLE: 'Contract',
        SEARCH_CONTRACT: 'Search contract',
        TARIFF_TABLE: 'Tariff Table',
        CUSTOMER_LOYALTY: 'Customer loyalty',
        NO_LOYALTY: 'Customer has no contracts',
        SUBSCIPTION_INFO: {
          COMMITMENT: 'Commitment',
          PAID_UNTIL: 'Paid until',
          HARDWARE: 'Hardware',
        },
        EXTRA: {
          TITLE: 'Extras',
          OFFERINGS: 'Extra offerings',
          USERS_DOES_NOT_SUPPORT: 'This subscription doesn’t support extra users',
          USERS: 'Extra Users',
          YOU_HAVE_EXISTING_SUBSCRIPTION:
            'You have existing subscription, would you like to include them in your family?',
          CURRENT: 'Current',
          AFTER_INCLUSION_IN_FAMILY: 'After inclusion in family',
          INCLUDE_IN_FAMILY: 'Include in family',
        },
        FINISH: {
          CUSTOMER_INFO: 'Customer information',
          SUMMARY_INFO: 'Summary of your order :',
          CONTRACT_ACTIVATION_INFO: 'Hardware information :',
          SIM_CONTRACT_INFO: 'Activation and number selection :',
          DETAILS: 'Details :',
          INFORMATION_ABOUT_EXTRA_USER: 'Information about extra user :',
          SIM_SENT_BY_POST: 'Sim sent by post',
          EMAIL_SENT: 'Email sent',
        },
        OVERVIEW: {
          CAMPAIGNS: 'Campaigns',
          ADDITIONAL_PRODUCTS_ARE_AVAILABLE_FOR_SELECTED_CONTRACT:
            'Additional products are available for selected contract',
          INSURANCES_AVAILABLE_FOR_SELECTED_CONTRACT:
            'Available insurances for selected contract',
          NO_AVAILABLE_FOR_SELECTED_CONTRACT:
            'There are no selectable add-ons for this contract',
          MONTH: 'month',
          PERSONS: 'persons',
          SELECT: 'Select',
          UNSELECT: 'Unselect',
          SELECTED: 'Selected',
          TITLE: 'Add-ons',
          INSURANCE: 'Insurances: Screen protector',
          TABLE: {
            ARTICLE: 'Article',
            NAME: 'Name',
            QTY: 'QTY',
            PRICE: 'Price',
          },
          INTERNAL_INFO: {
            TITLE: 'Internal info',
          },
        },
        OFFERING_SUMMARY: {
          TITLE: 'Offering Summary',
        },
        SUCCESS: {
          MESSAGE: 'Your order is placed. Order id: ',
          NEXT: 'Catalog',
          BACK: 'Stay here',
          ICONS: {
            LINK: 'Show link',
            PHOTO_CAMERA: 'Show QR code',
            EMAIL: 'Send agreement email',
            ASSIGNMENT: 'Waiting for signment',
          },
        },
        CHOOSE_NUMBER_BLOCK: {
          YOUR_NUMBER: 'Your number',
          GET_NEW_NUMBER: 'Get a new number',
          ASSIGNED_NUMBER_BY_PROVIDER: 'Assigned number by provider.',
          ASSIGNED_TELEPHONE_NUMBER: 'Assigned telephone number',
          KEEP_EXISTING_NUMBER: 'Keep existing number',
          TRANSFER_OF_NUMBERS: 'Transfer of numbers',
          PORTING_NUMBER_NOTICE:
            'If you are porting your phone number from another operator it can take up to 5 workdays. You have t own the number. ' +
            "If the provider dosen't release the number a new phonnember will be assigned",
          MY_EXISTING_NUMBER: 'Existing number - 46712345678',
          MOVE_NUMBER: 'Move number from other provider',
          YES: 'Yes',
          SELECT_PREFERRED_NUMBER: 'Select preferred number',
          SIM_DELIVERY: 'SIM delivery method',
          ICC_NUMBER: 'ICC number',
          START_DATE: 'Start date',
          ACTIVATION_DATE: 'Activation date',
        },
        PRICE: {
            TITLE: 'Price',
        },
        DETAILS: {
          TITLE: 'Details',
          TITLE_CUSTOMER_INFORMATION: 'Customer information',
          TITLE_BANK_ACCOUNT: 'Bank account',
          TITLE_SUBSCRIPTION: 'Subscription required information',
          SUBSCRIPTION: 'move number from other provider',
          BANK_ID: {
            SKIP: 'Skip BankID verification',
            PREFERRED_METHOD: 'Preferred identification method:'
          },
          FORM: {
            CUSTOMERID: 'Customer Id',
            ADDRESSCARDNUMBER: 'Address Card Number',
            BIRTHDATE: 'Birth date',
            MOTHERNAME: 'Mother Name',
            FIRSTNAME: 'First name',
            LASTNAME: 'Last name',
            ADDRESS: 'Address',
            ZIPCODE: 'Zip Code',
            CITY: 'City',
            PHONENUMBER: 'Phone number',
            EMAIL: 'Email',
            ACCOUNTBANK: 'Account bank',
            BANKNAME: 'Bank name',
            IBAN: 'IBAN',
            SUBSCRIPTIONPHONENUMBER: 'Subscription Phone number',
            PROVIDER: 'Provider',
            SSN: 'Social security number',
            SSN_12_digits: 'Social security number 12 digits'
          },
          VALIDATION: {
            INVALID: '{{name}} is not valid',
            REQUIRED: '{{name}} is required',
            MIN_LENGTH: '{{name}} minimum length is {{min}}',
            AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
            NOT_FOUND: 'The requested {{name}} is not found',
            INVALID_LOGIN: 'The login detail is incorrect',
            REQUIRED_FIELD: 'Required field',
            MIN_LENGTH_FIELD: 'Minimum field length:',
            MAX_LENGTH_FIELD: 'Maximum field length:',
            INVALID_FIELD: 'Field is not valid',
            FORBIDDEN_SYMBOLS_FIELD: 'Field contains forbidden characters',
          },
          DIALOG: {
              BANK_ID_VALIDATION: {
                  WAITING_CUSTOMER: 'Waiting for customer BankID verification',
                  WAITING: 'Waiting for manager BankID verification',
                  TIMEOUT: 'BankID timeout p.g.a.',
                  FAILED: 'BankID verification failed',
                  TELIA: 'Enter your Tholbox user id',
                  TELE2: 'Scan qr with BankId to provide your Vendo user id',
              }
            },
        },
        ACTIVATION: {
          TITLE: 'Activation info',
          IMEI_NUMBER: 'Imei number',
          SIM_NUMBER: 'Sim card number',
          SUBSCRIPTION_NUMBER: 'Subscription number',
          AGREEMENT_NUMBER: 'Agreement number',
            MANUAL: 'Manual flow',
            MANUAL_CONFIRM: 'Kryssa i nedan om du måste lägga order manuellt i Vendo (Ej API order)',
            TELIA_MANUAL_CONFIRM: 'Kryssa i nedan om du måste lägga order manuellt i Tholbox (Ej API order)',
          USER_INFORMED: 'I have informed the customer about the following:',
            TELIA: {
              CUSTOMER_AGREEMENT_TEXT: 'If necessary to complete the purchase, a credit report will be obtained'
            }
        },
        DONE: {
          TITLE: 'Summary',
        },
        BANNER: {
            CAMPAIGN: 'Campaign',
            ACTIVE: 'is currently active',
        }
      },
    },
    STAFF_BONUS: {
        TITLE: 'Staff bonus ',
        EXPORT_ACTION: 'Export to xls',
        FILTERS: {
            HEADQUARTER: 'Headquarter',
            START_DATE: 'Start date',
            END_DATE: 'End date',
            GROUP_BY: 'Group by',
            STORE: 'Store',
            EMPLOYEE: 'Employee',
        },
        TABLE: {
            COLUMN_NAMES: {
                DATE: 'Date',
                NAME: 'Name',
                STORE: 'Store',
                NUMBER_OF_ORDERS: 'Number of orders',
                TOTAL: 'Total',
                BONUS: 'Bonus',
            }
        }
    },
    CAMPAIGNS: {
        LIST: {
            TITLE: 'Campaigns list',
            FILTERS: {
                HEADQUARTER: 'Headquarter',
                NAME: 'Campaign name filter',
                STATUS: 'Status filter',
                STORE: 'Store',
            },
            TABLE: {
                COLUMN_NAMES: {
                    NAME: 'Campaign name',
                    START_DATE: 'Start date',
                    END_DATE: 'End date',
                    TYPE: 'Type',
                    STORES: 'Stores',
                    STATUS: 'Status',
                    ACTIONS: 'Actions'
                },
                PAGINATOR: {}
            },
            ACTIONS: {
                ADD: 'Add new campaign',
                PAUSE: 'Pause',
                UNPAUSE: 'Resume',
                APPROVE: 'Approve',
                DUPLICATE: 'Duplicate',
                DELETE: 'Delete',
            },
        },
        EDIT: {
            TITLE: {
                CREATE: 'Create campaign',
                EDIT: 'Edit campaign',
            },
            ACTIONS: {
                UPLOAD_PDF: 'Upload campaign PDF',
                SAVE: 'Save',
            },
            HEADQUARTER: 'Select headquarter',
            STORE: 'Select store',
            NAME: 'Campaign name',
            START_DATE: 'Start date',
            END_DATE: 'End date',
            DESCRIPTION: 'Campaign description (optional)',
            TYPE: {
                TITLE: 'Type',
                DISCOUNT: 'General discount',
                BUNDLING: 'Bundling',
            },
            CRITERIA: {
                TITLE: 'Criteria',
                PRODUCT_GROUP: 'Product group',
                DEVICES: 'Devices',
                DEVICE_VALUE: 'Device value more than',
                PROVIDER: 'Provider',
                CONTRACT: 'Contract',
            },
            DISCOUNT: {
                TYPE: 'Discount type (% or value)',
                AMOUNT: 'Discount amount',
                OPTION: {
                    PERCENT: 'percent',
                    VALUE: 'value'
                }
            },
            BUNDLINGS: {
                TITLE: 'Select product',
                PRODUCT: 'Bundling product',
                PRODUCTS_ALLOWED: '№ of products allowed',
                VALUE_UP_TO: 'Products for a value up to',
            },
            CAN_BE_COMBINED: 'Can be combined with other campaigns',
            CAN_APPLY_ANOTHER_DISCOUNT: 'Can apply another discount'
        }
    },
    HEADQUARTERS: {
        TITLE: {
            NEW: 'Create headquarter',
            EDIT: 'Edit headquarter'
        },
        EDIT: {
            ACTIONS: {
                BACK: 'Back',
                RESET: 'Reset',
                SAVE: 'Save',
            },
            BASIC_INFO: {
                LABEL: 'Basic info',
                NAME: {
                    EDIT: 'Enter name',
                    TITLE: 'Name',
                    HINT: `Please enter Name`,
                    ERROR: {
                        REQUIRED: `Name is required`,
                        PATTERN: `Name contains invalid characters`,
                    }
                },
            }
        },
    },
    SETTINGS: {
      TITLE: 'Settings',
      HEADQUARTER: 'Headquarter',
      TABLE: {
        TITLE: {
          CATEGORIES_SUPPORT: 'Categories support',
          CATEGORIES_API_SUPPORT: 'Categories API support',
          CONTRACT_GROUP: 'Contract group',
          DEVICE_GROUP: 'Device group',
        },
        CONTENT: {
          NEW_CATEGORY: 'New category...',
        },
      },
      LOADING: {
        CONTRACT_GROUP: 'The contract group is loading',
        DEVICE_GROUP: 'The device group is loading',
      },
      BUTTON: {
        UPDATE: 'Update'
      }
    },
    ITEMS_PER_PAGE_LABEL: 'Items per page',
    CUSTOMER_DATA: 'Customer data',
    DISCOUNT_CONTRACT: 'Discount contract',
    TO_PAY_FLAT: 'To pay',
    MONTHLY_COST_FLAT: 'Monthly Cost',
    GRABBED_FLAT: 'Grabbed',
    SIM_CARD_ACTIVATE: 'SIM card activation',
    SIM_CARD_ACTIVATED: 'Sim card activated',
    CONTRACTS_FLAT: 'Contracts',
    INFORMATION_LEAFLETS: 'Information leaflets',
    ORDER_STATUS_VODAFONE_NEW: 'New review',
    NEW: 'New review',
    ORDER_STATUS_VODAFONE_SUCCESSFUL_REVIEW: 'Successful Vodafone review',
    SUCCESSFUL_REVIEW: 'Successful Vodafone review',
    ORDER_STATUS_VODAFONE_UNSUCCESSFUL_REVIEW: 'Unsuccessful Vodafone review',
    UNSUCCESSFUL_REVIEW: 'Unsuccessful Vodafone review',
    ORDER_STATUS_VODAFONE_INCORRECT_DETAILS: 'Incorrect details',
    INCORRECT_DETAILS: 'Incorrect details',
    ORDER_STATUS_BUDAPEST_BANK_NEW: 'New request',
    ORDER_STATUS_BUDAPEST_BANK_CREDIT_RAITING_IN_PROGRESS:
      'Financing credit assessment in progress',
    ORDER_STATUS_BUDAPEST_BANK_SUCCESSFUL_LOAN_REQUEST:
      'Successful loan application',
    ORDER_STATUS_BUDAPEST_BANK_UNSUCCESSFUL_LOAN_REQUEST_OFFER_CANCELLATION:
      'Unsuccessful loan application - Offer cancellation',
    ORDER_STATUS_BUDAPEST_BANK_UNSUCCESSFUL_LOAN_REQUEST_CASH_PURCHASE:
      'Unsuccessful loan application - Cash purchase',
    ORDER_STATUS_BUDAPEST_BANK_NO_FINANCING: 'No financing',
    ORDER_STATUS_TO_HANDLE: 'To Handle',
    ORDER_STATUS_DELETED_OFFER: 'Deleted offer',
    ORDER_STATUS_INCORRECT_DETAILS: 'Incorrect details',
    ORDER_STATUS_OFFERING_CANCELLED: 'Offering cancelled',
    CHECK_STATUS: 'Check status',
    CHECK_STATUS_TOOLTIP:
      '• Check that all required services and documents of the customer waiting to be called + n are available (Vodafone, G +, BB, MM)!',
    INFO_2_TOOLTIP:
      "• Click the 'Create MCC Document' button and print it out.",
    INFO_3_TOOLTIP:
      '•Hívd be a vásárlót a Linistry\n' +
      ' segítségével pulthoz és azonosítsd!',
    INFO_4_TOOLTIP:
      '•GDPR nyilatkozat és Vodafone szolgáltatói\n' +
      ' szerződés ELEKTRONIKUS aláírása',
    INFO_5_TOOLTIP:
      '•Kasszázd a szükséges tételeket\n ' +
      '(átvételi bizonylat, Garancia Plusz)!',
    INFO_6_TOOLTIP:
      '•Ellenőrizd a számát, majd aktiváld az \n ' +
      ' átadott SIM kártyát a vásárló részére!',
    INFO_7_TOOLTIP:
      '•Érvényesítsd a termék(ek) jótállási jegyét,\n' +
      ' kötvényesítsd a Garancia Plusz-t és add ki a terméke(ke)t a vásárlónak!',
    INFO_8_TOOLTIP:
      '•Ellenőrizd hogy a termék és a\n' +
      ' dokumentumok aláírásra, átadásra kerültek!',
    SIGN_OUT: 'Sign Out',
    CARD_TITLE_INFORMATION: 'Information!',
    CLOSE_ORDER_TITLE: 'Close order and activate insurance',
    ORDER_CLOSE_BUTTON: 'Order ready (Closed)',
    LIMIT_ACCESSORY_MSG: 'You can choose no more than 5 accessories',
    deposit: 'Deposit',
  },
};
