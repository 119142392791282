import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {ActionOrderUpdateOrder, ActionSelectDevices, ActionSelectDevicesWarranty, ActionSetCheckout, ActionSetProviderOnly, ActionSetProviders,} from '../_actions/devices.actions';
import {DeviceModel} from '../_models/device.model';
import {DevicesService} from '../_services/service.devices';
import {DevicesReducerState} from '../_reducers/devices.reducers';
import {OrderModel} from '../_models/order.model';
import {take} from 'rxjs/operators';
import {HeadquarterProviderModel} from '../_models/headquarterProviderModel';
import {AppState} from "../../reducers";
import {LocalStorageService} from "../../services/local-storage.service";
import {ProviderService} from "../_services/provider.service";

@Injectable()
export class ResolversDetailsPhone implements Resolve<any> {
    constructor(
        private router: Router,
        private devicesService: DevicesService,
        private storeDevices: Store<AppState>,
        private providerService: ProviderService,
    ) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<any> | any {
        if (route.params.id.includes('contract') && route.queryParams.providerId) {
            return this.setProviderOnly(route);
        } else {
            return this.setDeviceAndProvidersData(route);
        }
    }

    private async checkStoreProviders(state: DevicesReducerState): Promise<any> {
        if (!state.providers.length) {
            const headquarterId = LocalStorageService.get('headquarter').id;
            const providers = await this.providerService
                .getOnlyProviders(headquarterId)
                .toPromise();
            this.storeDevices.dispatch(new ActionSetProviders(providers));
        }
    }

    private async setDeviceAndProvidersData(route): Promise<any> {
        try {
            const res = await this.storeDevices.select('DevicesStore').pipe(take(1))
                .toPromise();
            await this.checkStoreProviders(res);
            return await this.setDeviceInfo(route);
        } catch (e) {
            return console.log('err ResolversDetailsPhone: ', e);
        }
    }

    private async setProviderOnly(route): Promise<any> {
        let newDevice = new DeviceModel();
        this.storeDevices.dispatch(new ActionSelectDevices(newDevice));
        const store = await this.storeDevices.select('DevicesStore').pipe(take(1))
            .toPromise();
        await this.checkStoreProviders(store);
        const res = await this.storeDevices.select('DevicesStore').pipe(take(1))
            .toPromise();
        const headquarterProvider = res.providers.find((provider: HeadquarterProviderModel) => provider.id === +route.queryParams.providerId);
        this.storeDevices.dispatch(new ActionSetProviderOnly(headquarterProvider));
        const newOrder = new OrderModel();
        newOrder.device = newDevice;
        this.storeDevices.dispatch(new ActionOrderUpdateOrder(newOrder));
    }

    private async setDeviceInfo(route): Promise<any> {
        const selectDevice = await this.devicesService
            .getOneDevice(route.params.id)
            .toPromise();
        if (!selectDevice) {
            this.router.navigate(['/catalog/list']);
        }
        const {device, warTimes} = selectDevice;
        const newDevice = new DeviceModel(device);
        const newOrder = new OrderModel();
        if (!!route.queryParams.changeContract && +route.params.id) {
            this.storeDevices.dispatch(new ActionSetCheckout(null));
        }
        this.storeDevices.dispatch(new ActionSelectDevices(newDevice));
        this.storeDevices.dispatch(new ActionSelectDevicesWarranty(warTimes));
        newOrder.device = newDevice;
        this.storeDevices.dispatch(new ActionOrderUpdateOrder(newOrder));
        return route.params;
    }
}
