<div class="header-container">
    <mat-card-header class="header">
        <mat-card-title class="title" [ngClass]="classes">
            <div [hidden]="noTitle">
                <ng-content *ngIf="!title" select="[ktPortletTitle]"></ng-content>
                <h3 *ngIf="title" [innerHTML]="title" class="title-text"></h3>
            </div>
            <div #refTools [hidden]="hideTools" >
                <ng-content select="[ktPortletTools]"></ng-content>
            </div>
            <ng-content></ng-content>
        </mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
</div>

