import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
    private readonly apiUrl = environment.api_url;
  constructor(private http: HttpClient,) { }

    setStore(storeId: number) {
        return this.http.patch(`${this.apiUrl}/user/store`, {storeId});
    }
}
