<div ngbDropdown
    placement="bottom-right"
    *ngIf="user$ | async as _user"
    class="kt-header__topbar-item kt-header__topbar-item--user">
    <div ngbDropdownToggle class="kt-header__topbar-wrapper">
        <div class="kt-header__topbar-user">
            <!-- <span class="kt-header__topbar-welcome kt-hidden-mobile" *ngIf="showHi">Hi,</span>
                  <span class="kt-header__topbar-username kt-hidden-mobile" *ngIf="showHi">{{_user.email}}</span> -->

            <span class="kt-header__topbar-welcome" *ngIf="showHi"
            >{{ 'USER.HELLO' | translate }},</span
            >
            <span class="kt-header__topbar-username" *ngIf="showHi">{{
                _user.email
                }}</span>

            <img *ngIf="showAvatar" alt="Pic" [attr.src]="_user.pic"/>
            <span
                *ngIf="showBadge"
                class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold"
            >{{ _user.firstname | firstLetter }}</span
            >
        </div>
    </div>
    <div ngbDropdownMenu
        class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <div
            class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
            style="background-image: url(./assets/media/misc/bg-1.jpg)">
            <div class="kt-user-card__avatar">
                <img *ngIf="showAvatar" alt="Pic" [attr.src]="_user.pic"/>
                <span
                    [hidden]="false"
                    class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success"
                >{{ _user.email | firstLetter }}</span
                >
            </div>
            <div class="kt-user-card__name">
                {{ _user.email }}
            </div>
        </div>
        <div style="display: flex; flex-direction: column; padding: 10px">
            <button *ngIf="_user.headquarter.storeChangingAllowed" mat-button mat-raised-button color="primary" (click)="showStoreSelection()">Select store</button>
            <div class="kt-notification">
                <mat-slide-toggle *ngIf="!production && !stage" (change)="changeMode($event)" [(ngModel)]="expertMode">Expert
                    mode
                </mat-slide-toggle>

                <div class="kt-notification__custom">
                    <a
                        href="javascript:;"
                        (click)="logout()"
                        class="btn btn-outline-brand btn-upper btn-sm btn-bold"
                    >{{ 'SIGN_OUT' | translate }}</a
                    >
                </div>
            </div>
        </div>
        <ul class="kt-nav kt-margin-b-10 kt-hidden">
            <li class="kt-nav__item">
                <a routerLink="profile" class="kt-nav__link">
          <span class="kt-nav__link-icon"
          ><i class="flaticon2-calendar-3"></i
          ></span>
                    <span class="kt-nav__link-text">My Profile</span>
                </a>
            </li>
            <li class="kt-nav__item">
                <a routerLink="profile" class="kt-nav__link">
          <span class="kt-nav__link-icon"
          ><i class="flaticon2-browser-2"></i
          ></span>
                    <span class="kt-nav__link-text">My Tasks</span>
                </a>
            </li>
            <li class="kt-nav__item">
                <a routerLink="profile" class="kt-nav__link">
                    <span class="kt-nav__link-icon"><i class="flaticon2-mail"></i></span>
                    <span class="kt-nav__link-text">Messages</span>
                </a>
            </li>
            <li class="kt-nav__item">
                <a routerLink="profile" class="kt-nav__link">
                    <span class="kt-nav__link-icon"><i class="flaticon2-gear"></i></span>
                    <span class="kt-nav__link-text">Settings</span>
                </a>
            </li>
            <li class="kt-nav__item kt-nav__item--custom kt-margin-t-15">
                <a
                    (click)="logout()"
                    class="btn btn-outline-brand btn-upper btn-sm btn-bold"
                >Sign Out</a
                >
            </li>
        </ul>
    </div>
</div>
