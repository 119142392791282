import { ContractModel } from './contract.model';
import { StoreModel } from '../../store/_models/store.model';

/**
 * Айтунс и другие дополнительные продукты, по которому сортируем Контракт
 */
export class ProductModel {
  id: number;
  contractId: number = 0;
  contract: ContractModel;
  storeId: number = 0;
  store: StoreModel;

  constructor(init?: Partial<ProductModel>) {
    Object.assign(this, init);
  }
}
