import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import * as objectPath from 'object-path';
import {
  LayoutConfigService,
  MenuAsideService,
  MenuOptions,
  OffcanvasOptions,
} from '../../../../core/_base/layout';
import { HtmlClassService } from '../../services/html-class.service';
import { ProviderService } from '../../../../core/catalog';
import { currentUser } from '../../../../core/auth';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';

@Component({
  selector: 'kt-aside-left',
  templateUrl: './aside-left.component.html',
  styleUrls: ['./aside-left.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideLeftComponent implements OnInit, AfterViewInit {
  @ViewChild('asideMenu', {static: true}) asideMenu: ElementRef;

  currentRouteUrl: string = '';
  insideTm: any;
  outsideTm: any;
  isCampaignModule: boolean;
  isStaffBonusModule: boolean;

  menuCanvasOptions: OffcanvasOptions = {
    baseClass: 'kt-aside',
    overlay: true,
    closeBy: 'kt_aside_close_btn',
    toggleBy: {
      target: 'kt_aside_mobile_toggler',
      state: 'kt-header-mobile__toolbar-toggler--active',
    },
  };

  menuOptions: MenuOptions = {
    scroll: null,
    submenu: {
      desktop: { default: 'dropdown' },
      tablet: 'accordion',
      mobile: 'accordion',
      accordion: {
        expandAll: false,
      },
    },
  };

  constructor(
    public htmlClassService: HtmlClassService,
    public menuAsideService: MenuAsideService,
    public layoutConfigService: LayoutConfigService,
    private router: Router,
    private render: Renderer2,
    public providerService: ProviderService,
    private store: Store<AppState>,
  ) {}

  ngAfterViewInit(): void {}

  ngOnInit() {
    this.currentRouteUrl = this.router.url.split(/[?#]/)[0];

    this.store
      .pipe(select(currentUser))
      .subscribe((res) => {
        if (res?.headquarter) {
          this.isCampaignModule = res.headquarter.campaignModule;
          this.isStaffBonusModule = res.headquarter.staffBonusModule;
        }
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(
        (event) => (this.currentRouteUrl = this.router.url.split(/[?#]/)[0]),
      );

    const config = this.layoutConfigService.getConfig();

    if (
      objectPath.get(config, 'aside.menu.dropdown') !== true &&
      objectPath.get(config, 'aside.self.fixed')
    ) {
      this.render.setAttribute(
        this.asideMenu.nativeElement,
        'data-ktmenu-scroll',
        '1',
      );
    }

    if (objectPath.get(config, 'aside.menu.dropdown')) {
      this.render.setAttribute(
        this.asideMenu.nativeElement,
        'data-ktmenu-dropdown',
        '1',
      );
      this.render.setAttribute(
        this.asideMenu.nativeElement,
        'data-ktmenu-dropdown-timeout',
        objectPath.get(config, 'aside.menu.submenu.dropdown.hover-timeout'),
      );
    }
  }

  titleFilter(title: string) {
    const campaingDisabled = title === 'Campaigns' && !this.isCampaignModule;
    const staffBonusDisabled =
      title === 'Staff bonus' && !this.isStaffBonusModule;

    if (campaingDisabled || staffBonusDisabled) {
      return;
    }

    return title;
  }

  isMenuItemIsActive(item): boolean {
    if (item.submenu) {
      return this.isMenuRootItemIsActive(item);
    }

    if (!item.page) {
      return false;
    }

    return this.currentRouteUrl.indexOf(item.page) !== -1;
  }

  isMenuRootItemIsActive(item): boolean {
    let result: boolean = false;

    for (const subItem of item.submenu) {
      result = this.isMenuItemIsActive(subItem);
      if (result) {
        return true;
      }
    }

    return false;
  }

  mouseEnter(e: Event) {
    if (document.body.classList.contains('kt-aside--fixed')) {
      if (this.outsideTm) {
        clearTimeout(this.outsideTm);
        this.outsideTm = null;
      }

      this.insideTm = setTimeout(() => {
        if (
          document.body.classList.contains('kt-aside--minimize') &&
          KTUtil.isInResponsiveRange('desktop')
        ) {
          this.render.removeClass(document.body, 'kt-aside--minimize');
          this.render.addClass(document.body, 'kt-aside--minimize-hover');
        }
      }, 50);
    }
  }

  mouseLeave() {
    if (document.body.classList.contains('kt-aside--fixed')) {
      if (this.insideTm) {
        clearTimeout(this.insideTm);
        this.insideTm = null;
      }

      this.outsideTm = setTimeout(() => {
        if (
          document.body.classList.contains('kt-aside--minimize-hover') &&
          KTUtil.isInResponsiveRange('desktop')
        ) {
          this.render.removeClass(document.body, 'kt-aside--minimize-hover');
          this.render.addClass(document.body, 'kt-aside--minimize');
        }
      }, 100);
    }
  }

  getItemCssClasses(item) {
    let classes = 'kt-menu__item';

    if (objectPath.get(item, 'submenu')) {
      classes += ' kt-menu__item--submenu';
    }

    if (!item.submenu && this.isMenuItemIsActive(item)) {
      classes += ' kt-menu__item--active kt-menu__item--here';
    }

    if (item.submenu && this.isMenuItemIsActive(item)) {
      classes += ' kt-menu__item--open kt-menu__item--here';
    }

    if (objectPath.has(item, 'custom-class')) {
      classes += ' ' + item['custom-class'];
    }

    if (objectPath.get(item, 'icon-only')) {
      classes += ' kt-menu__item--icon-only';
    }

    return classes;
  }

  getItemAttrSubmenuToggle(item) {
    let toggle = 'hover';
    if (objectPath.get(item, 'toggle') === 'click') {
      toggle = 'click';
    } else if (objectPath.get(item, 'submenu.type') === 'tabs') {
      toggle = 'tabs';
    }

    return toggle;
  }
}
