import { PermanentAddress } from '../../order';

export class CustomerModel {
  customerId: string = '';
  deviceId: number = 0;
  contractId: number = 0;
  provider: string = '';
  phoneNumber: string = '';
  birthDate: string = '';
  firstname: string = '';
  lastname: string = '';
  address: string = '';
  zipCode: string = '';
  city: string = '';
  email: string = '';
  accountBank: string = '';
  monthlyCost: number = null;
  personalDataFetchedFromApi: boolean = true;
  mobilePhoneNumber: number = null;
  gender: string = null;
  indentityCardType: string = null;
  idCardNumber: string = null;
  birthLastname: string = null;
  birthFirstname: string = null;
  mothersBirthFirstname: string = null;
  mothersBirthLastname: string = null;
  placeOfBirth: string = null;
  houseNumber: string = null;
  building: string = null;
  customerCode: string = null;
  identificationMethod: string = null;
  identificationNumber: string = null;
  skipBankIdVerification: boolean = false;
  residenceAddress?: PermanentAddress = null;
  bankIdPayload?: any = null;
  bankIdOrderRef?: any = null;
  subsAvailable: number = 10;

  constructor(init?: Partial<CustomerModel>) {
    Object.assign(this, init);
  }
}
