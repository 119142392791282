<kt-portlet>
  <kt-portlet-header
    [title]="getComponentTitle()"
    [class]="'kt-portlet__head--lg'"
    [viewLoading$]="loading$"
  >
    <ng-container ktPortletTools>
      <button
        class="btn btn-secondary kt-margin-r-10"
        mat-raised-button
        matTooltip="Reset comment"
        (click)="onReset()"
        [disabled]="commentToCancelOrderForm.pristine"
        tabindex="51"
      >
        <i class="material-icons">restore</i>
        <span class="kt-hidden-mobile">{{
          'ORDER.BTN_RESET' | translate
        }}</span>
      </button>

      <button
        class="btn btn-primary kt-margin-r-10"
        mat-raised-button
        matTooltip="Save comment"
        color="primary"
        (click)="onSumbit()"
        [disabled]="commentToCancelOrderForm.invalid"
        tabindex="50"
      >
        <i class="material-icons">comment</i>
        <span class="kt-hidden-mobile">{{
          'ORDER.BTN_SAVE_COMMENT' | translate
        }}</span>
      </button>
    </ng-container>
  </kt-portlet-header>
  <kt-portlet-body>
    <section class="" style="width: 100%">
      <mat-form-field class="mat-form-field-fluid">
        <textarea
          tabindex="20"
          rows="3"
          matInput
          placeholder="{{ 'ORDER.FORM.COMMENT' | translate }}"
          [formControl]="commentToCancelOrderForm"
          style="max-height: 90px"
        ></textarea>
        <mat-error *ngIf="commentToCancelOrderForm.hasError('require')">
          {{ 'ORDER.FORM.COMMENT' | translate }}
          {{ 'ORDER.VALIDATION.REQUIRED_FIELD' | translate }}
        </mat-error>
        <mat-error *ngIf="commentToCancelOrderForm.hasError('pattern')">
          {{ 'ORDER.FORM.COMMENT' | translate }}
          {{ 'ORDER.VALIDATION.FORBIDDEN_SYMBOLS_FIELD' | translate }}
        </mat-error>
      </mat-form-field>
    </section>

    <section>
      <div class="kt-widget1">
        <mat-table
          class="lmat-elevation-z8"
          [dataSource]="dataSource"
          matSort
          #sort1="matSort"
        >
          <ng-container matColumnDef="createdAt">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Date(D/M/Y)</mat-header-cell
            >
            <mat-cell
              *matCellDef="let element"
              class="mat-column d-flex align-items-center justify-content-center kt-padding-l-5"
            >
              {{ element.createdAt | date: 'dd/MM/yy HH:MM' }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="text">
            <mat-header-cell
              *matHeaderCellDef
              class="d-flex flex-column align-items-center"
            >
              text
            </mat-header-cell>
            <mat-cell
              *matCellDef="let element"
              class="mat-column d-flex align-items-center"
            >
              <div class="kt-widget1__item">
                <div class="kt-widget1__info">
                  <h5 class="kt-widget1__title">
                    {{ element.user.firstname }} {{ element.user.lastname }}
                  </h5>
                  <span class="kt-widget1__desc">{{ element.text }}</span>
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <!--
                                        <ng-container matColumnDef="actions">
                                            <mat-header-cell *matHeaderCellDef > Actions </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="mat-column-actions">
                                                <button mat-icon-button type="button" color="warn"  matTooltip="Delete" (click)="onDelete(element)" >
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </mat-cell>
                                        </ng-container>
                    -->

          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>

        <mat-paginator
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 15, 25]"
          [showFirstLastButtons]="true"
        ></mat-paginator>
      </div>
    </section>
  </kt-portlet-body>
</kt-portlet>
