// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
// Fake API Angular-in-memory
// Translate Module
import { TranslateModule } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PartialsModule } from '../../../partials/partials.module';
import { ModuleGuard } from '../../../../core/auth';
import {
  EmployeesRequestsEffects,
  employeesRequestsReducer,
  EmployeesStoreRequestsEffects,
  employeesStoreRequestsReducer,
  HeadquarterEffects,
  HeadquarterEmployeeEffects,
  headquarterEmployeesReducer,
  HeadquarterEmployeesService,
  headquartersReducer,
  HeadquartersService,
  HeadquarterStoreEffects,
  headquarterStoresReducer,
  HeadquarterStoresService,
} from '../../../../core/headquarter';
import {
  HttpUtilsService,
  InterceptService,
  LayoutUtilsService,
  TypesUtilsService,
} from '../../../../core/_base/crud';
import {
  ActionNotificationComponent,
  DeleteEntityDialogComponent,
  FetchEntityDialogComponent,
  UpdateStatusDialogComponent,
} from '../../../partials/content/crud';
import { HeadquarterComponent } from './headquarter.component';
import { HeadquartersListComponent } from './headquarters/headquarters-list/headquarters-list.component';
import { HeadquarterEditComponent } from './headquarters/headquarter-edit/headquarter-edit.component';

import { HeadquarterEmployeesListComponent } from './headquarters/_subs/employees/employees-list/employees-list.component';
import { StoresListComponent } from './headquarters/_subs/stores/stores-list/stores-list.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from '../../../../../environments/environment';
import {
  NgbProgressbarModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { HeadquarterEmployeeEditDialogComponent } from './headquarters/_subs/employees/employee-edit/employee-edit-dialog.component';
import { StoreEditDialogComponent } from './headquarters/_subs/stores/store-edit/store-edit-dialog.component';
import { ProviderCalcComponent } from './headquarters/_subs/provider-calc/provider-calc.component';
import { HeadquarterNewComponent } from './headquarters/headquarter-new/headquarter-new.component';
import { EmployeesRequestsComponent } from './headquarters/_subs/employees/employees-requests/employees-requests.component';
import { ProductSettingsComponent } from './headquarters/_subs/product-settings/product-settings.component';

const routes: Routes = [
  {
    path: '',
    component: HeadquarterComponent,
    // data: { moduleName: 'headquarter' },
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: HeadquartersListComponent,
      },
      {
        path: 'add',
        component: HeadquarterNewComponent,
      },
      {
        path: 'management',
        component: HeadquarterEditComponent,
      },
      {
        path: 'edit',
        component: HeadquarterEditComponent,
      },
      {
        path: 'edit/:id',
        component: HeadquarterEditComponent,
      },
    ],
  },
];

@NgModule({
    imports: [
        MatDialogModule,
        CommonModule,
        HttpClientModule,
        PartialsModule,
        NgxPermissionsModule.forChild(),
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTooltipModule,
        NgbProgressbarModule,
        NgbTypeaheadModule,
        StoreModule.forFeature('headquarters', headquartersReducer),
        EffectsModule.forFeature([HeadquarterEffects]),
        StoreModule.forFeature('headquarterEmployees', headquarterEmployeesReducer),
        StoreModule.forFeature('employeesRequests', employeesRequestsReducer),
        StoreModule.forFeature('employeesStoreRequests', employeesStoreRequestsReducer),
        EffectsModule.forFeature([HeadquarterEmployeeEffects]),
        EffectsModule.forFeature([EmployeesRequestsEffects]),
        EffectsModule.forFeature([EmployeesStoreRequestsEffects]),
        StoreModule.forFeature('headquarterStores', headquarterStoresReducer),
        EffectsModule.forFeature([HeadquarterStoreEffects]),
    ],
    providers: [
        ModuleGuard,
        InterceptService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptService,
            multi: true,
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                height: 'auto',
                width: '900px',
            },
        },
        TypesUtilsService,
        LayoutUtilsService,
        HttpUtilsService,
        HeadquartersService,
        HeadquarterEmployeesService,
        HeadquarterStoresService,
    ],
    declarations: [
        HeadquarterComponent,
        // Headquarters
        HeadquartersListComponent,
        HeadquarterEditComponent,
        HeadquarterEmployeesListComponent,
        HeadquarterEmployeeEditDialogComponent,
        StoresListComponent,
        StoreEditDialogComponent,
        ProviderCalcComponent,
        HeadquarterNewComponent,
        EmployeesRequestsComponent,
        ProductSettingsComponent,
    ]
})
export class HeadquarterModule {}
