import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as objectPath from 'object-path';
import {LayoutConfigService, MenuConfigService, PageConfigService,} from '../../../../core/_base/layout';
import { HtmlClassService } from '../../services/html-class.service';
import { LayoutConfig } from '../../../../core/_config/dashboard/layout.config';
import { MenuConfig } from '../../../../core/_config/dashboard/menu.config';
import { PageConfig } from '../../../../core/_config/dashboard/page.config';
import { NgxPermissionsService } from 'ngx-permissions';
import { currentUserRoleIds, Permission } from '../../../../core/auth';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { takeUntil } from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
  selector: 'kt-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BaseComponent implements OnInit, OnDestroy {
  selfLayout: string;
  asideDisplay: boolean;
  subheaderDisplay: boolean;
  isDashboard: boolean = false;
  private destroyStream$ = new Subject();

  private currentUserPermissions$: Observable<Permission[]>;

  constructor(
    private layoutConfigService: LayoutConfigService,
    private menuConfigService: MenuConfigService,
    private pageConfigService: PageConfigService,
    private htmlClassService: HtmlClassService,
    private store: Store<AppState>,
    private permissionsService: NgxPermissionsService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.isDashboard = this.router.url.includes('/dashboard');
    this.loadConfigs();
  }

  ngOnDestroy(): void {
    this.destroyStream$.next();
    this.destroyStream$.complete();
  }

  loadRolesWithPermissions() {
    this.currentUserPermissions$ = this.store.pipe(select(currentUserRoleIds));
    this.currentUserPermissions$
      .pipe(takeUntil(this.destroyStream$))
      .subscribe((res) => {
        if (!res || res.length === 0) {
          return;
        }

        this.permissionsService.flushPermissions();
        this.permissionsService.loadPermissions(res.map((role) => role.name));
      });
  }

  private loadConfigs(): void {
    this.loadRolesWithPermissions();

    this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
    this.menuConfigService.loadConfigs(new MenuConfig().configs);
    this.pageConfigService.loadConfigs(new PageConfig().configs);

    this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

    this.layoutConfigService.onConfigUpdated$
      .pipe(takeUntil(this.destroyStream$))
      .subscribe((layoutConfig) => {
        document.body.className = '';
        this.htmlClassService.setConfig(layoutConfig);
      });
    const config = this.layoutConfigService.getConfig();
    this.selfLayout = objectPath.get(config, 'self.layout');
    this.asideDisplay = objectPath.get(config, 'aside.self.display');
    this.subheaderDisplay = objectPath.get(config, 'subheader.display');

    this.layoutConfigService.onConfigUpdated$
      .pipe(takeUntil(this.destroyStream$))
      .subscribe((cfg) => {
        setTimeout(() => {
          this.selfLayout = objectPath.get(cfg, 'self.layout');
        });
      });
  }
}
