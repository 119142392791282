// MODELS
export {
  ContractModel,
  ContractType,
  ContractLength,
  TypeCalculationUsageData,
  Insurance,
} from './_models/contract.model';
export { DeviceModel } from './_models/device.model';
export { OrderModel, OrderState } from './_models/order.model';
export {
  OrderCheckoutModel,
  TemporaryOrder,
} from './_models/order-checkout.model';
export { ProductModel } from './_models/product.model';
export { HeadquarterProviderModel } from './_models/headquarterProviderModel';
export { CustomerModel } from './_models/customer.model';
export { ActionInfo } from './_models/action-info.model';
export { TabTypeSwitch } from './_models/select-tab-type';

// ACTIONS
export {
  DevicesActions,
  ActionTypes,
  ActionSelectDevices,
  ActionSelectDevicesWarranty,
  ActionSetOptionsContract,
  ActionSetCheckout,
  ActionOrderUpdateOrder,
  ActionSetCustomer,
  ActionSetActionInfo,
  ActionResetInfo,
  ActionSetMonths,
  ActionSetAdditionalProducts,
  ActionSetProviderOnly,
  ActionSetDiscount,
  ActionRemoveStartingFee,
  ActionSetCurrentContractData,
  ActionSelectScreenProtector,
} from './_actions/devices.actions';

// REDUCERS
export {
  DevicesReducer,
  DevicesReducerState,
} from './_reducers/devices.reducers';

// RESOLVERS
export { ResolversDetailsPhone } from './_resolvers/details-phone.resolvers';

// SERVICES
export { ProviderService } from './_services/provider.service';
export { DevicesService } from './_services/service.devices';
export { CatalogService } from './_services/catalog.service';
