<ng-container *ngIf="selfLayout !== 'blank'; else blankLayout">
  <div class="kt-grid kt-grid--hor kt-grid--root">
    <kt-header-mobile></kt-header-mobile>
    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
      <ng-container *ngIf="asideDisplay">
        <button class="kt-aside-close" id="kt_aside_close_btn">
          <i class="la la-close"></i>
        </button>
        <kt-aside-left></kt-aside-left>
      </ng-container>
      <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
<!--        <div style="flex-basis: auto; flex-grow: 1; flex-shrink: 1; flex-direction: column;display: flex; padding-top: 119px;padding-left: 70px" id="kt_wrapper"> use instead predefined styles after-->
        <kt-header></kt-header>
          <kt-subheader *ngIf="subheaderDisplay && isDashboard"></kt-subheader>
          <div ktContentAnimate class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
              <router-outlet></router-outlet>
          </div>
        <kt-footer></kt-footer>
      </div>
    </div>
    <kt-scroll-top></kt-scroll-top>
  </div>
</ng-container>

<ng-template #blankLayout>
  <router-outlet></router-outlet>
</ng-template>

