import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DeviceModel } from '../_models/device.model';
import { environment } from '../../../../environments/environment';
import { HttpUtilsService } from '../../_base/crud';
import { CustomerModel } from '../_models/customer.model';
import {objectToStr} from "../../_base/crud/utils/http.utils";
import {IDeviceSearchParams} from "./models/device.models";
import { CategoryType } from '../_models/category-type.model';

@Injectable({ providedIn: 'root' })
export class DevicesService {
  httpHeaders = this.httpUtils.getHTTPHeaders();

  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {}

  dictionary(storeIds: number[], headquarterId: number, categoryType: CategoryType): Observable<any[]> {
    return this.http.get<any[]>(
      `${
        environment.api_url
      }/dictionary/devices?storeIds=${storeIds.toString()}&headquarterId=${headquarterId}&categoryType=${categoryType}`,
    );
  }

  autocompleteDevices(query: string): Observable<DeviceModel[]> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<any>(
      `${environment.api_url}/device/search?query=${query}`,
    );
  }

  autocomplete(searchParams: IDeviceSearchParams): Observable<DeviceModel[]> {
      const queryStr = objectToStr(searchParams);
      return this.http.get<any>(`${environment.api_url}/device/search?${queryStr}`,);
  }

  getOneDevice(deviceId: string | number): Observable<any> {
    return this.http.get<any>(`${environment.api_url}/device/${deviceId}`);
  }

  createOrder(customer: CustomerModel): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/order`, customer);
  }

  getBrands() {
    return this.http.get<any>(`${environment.api_url}/products/manufacturer`);
  }

  brandsDictionary(storeIds: number[], headquarterId: number, categoryType: CategoryType) {
    return this.http.get<any>(
      `${
        environment.api_url
      }/dictionary/manufacturer?storeIds=${storeIds.toString()}&headquarterId=${headquarterId}&categoryType=${categoryType}`,
    );
  }

  getManufacturers(headquarterId: number): Observable<{id: number, name: string}[]> {
    return this.http.get<any>(`${environment.api_url}/settings/manufacturers?headquarterId=${headquarterId}`);
  }
}
