// Models and Consts
export { HeadquarterModel } from './_models/headquarter.model';
export { HeadquarterEmployeeModel } from './_models/headquarter-employee.model';
export { HeadquarterStoreModel } from './_models/headquarter-store.model';
export { SPECIFICATIONS_DICTIONARY } from './_consts/specification.dictionary';

// DataSources
export { HeadquartersDataSource } from './_data-sources/headquarters.datasource';
export { HeadquarterEmployeesDataSource } from './_data-sources/headquarter-employees.datasource';
export { HeadquarterStoresDataSource } from './_data-sources/headquarter-stores.datasource';

// Actions
// Headquarter actions =>
export {
  HeadquarterActionTypes,
  HeadquarterActions,
  HeadquarterOnServerCreated,
  HeadquarterCreated,
  HeadquarterUpdated,
  HeadquartersStatusUpdated,
  OneHeadquarterDeleted,
  ManyHeadquartersDeleted,
  HeadquartersPageRequested,
  HeadquartersPageLoaded,
  HeadquartersPageCancelled,
  HeadquartersPageToggleLoading,
  HeadquartersActionToggleLoading,
} from './_actions/headquarter.actions';
// HeadquarterEmployee Actions =>
export {
  HeadquarterEmployeeActionTypes,
  HeadquarterEmployeeActions,
  HeadquarterEmployeeCreated,
  HeadquarterEmployeeUpdated,
  OneHeadquarterEmployeeDeleted,
  ManyHeadquarterEmployeesDeleted,
  HeadquarterEmployeesPageRequested,
  HeadquarterEmployeesPageLoaded,
  HeadquarterEmployeesPageCancelled,
  HeadquarterEmployeesPageToggleLoading,
  HeadquarterEmployeeOnServerCreated,
} from './_actions/headquarter-employee.actions';
// HeadquarterStore Actions =>
export {
  HeadquarterStoreActionTypes,
  HeadquarterStoreActions,
  HeadquarterStoreCreated,
  HeadquarterStoreUpdated,
  OneHeadquarterStoreDeleted,
  ManyHeadquarterStoresDeleted,
  HeadquarterStoresPageRequested,
  HeadquarterStoresPageLoaded,
  HeadquarterStoresPageCancelled,
  HeadquarterStoresPageToggleLoading,
  HeadquarterStoreOnServerCreated,
} from './_actions/headquarter-store.actions';

// Effects
export { HeadquarterEffects } from './_effects/headquarter.effects';
export { EmployeesStoreRequestsEffects } from './_effects/store-employees-requests.effects';
export { EmployeesRequestsEffects } from './_effects/employees-requests.effects';
export { HeadquarterEmployeeEffects } from './_effects/headquarter-employee.effects';
export { HeadquarterStoreEffects } from './_effects/headquarter-store.effects';
// Reducers
export { headquartersReducer } from './_reducers/headquarter.reducers';
export { headquarterEmployeesReducer } from './_reducers/headquarter-employee.reducers';
export { employeesRequestsReducer } from './_reducers/employees-reuests.reducers';
export { headquarterStoresReducer } from './_reducers/headquarter-store.reducers';
export { employeesStoreRequestsReducer } from './_reducers/store-employees-requests.reducers';

// Selectors
// Headquarter selectors
export {
  selectHeadquarterById,
  selectHeadquartersInStore,
  selectHeadquartersPageLoading,
  selectHeadquartersPageLastQuery,
  selectLastCreatedHeadquarterId,
  selectHasHeadquartersInStore,
  selectHeadquartersActionLoading,
  selectHeadquartersInitWaitingMessage,
} from './_selectors/headquarter.selectors';
// HeadquarterEmployee selectors =>
export {
  selectHeadquarterEmployeeById,
  selectHeadquarterEmployeesInStore,
  selectHeadquarterEmployeesPageLoading,
  selectLastCreatedHeadquarterEmployeeId,
} from './_selectors/headquarter-employee.selectors';
// HeadquarterStore selectors =>
export {
  selectHeadquarterStoreById,
  selectHeadquarterStoresInStore,
  selectHeadquarterStoresPageLoading,
  selectLastCreatedHeadquarterStoreId,
} from './_selectors/headquarter-store.selectors';

// Services
export {
  HeadquartersService,
  HeadquarterEmployeesService,
  EmployeesRequestsService,
  HeadquarterStoresService,
} from './_services/';
