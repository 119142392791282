import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {CoreModule} from '../../core/core.module';
import {
    ActionNotificationComponent,
    AlertComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
} from './content/crud';
import {
    CheckoutSuccessComponent,
    LanguageSelectorComponent,
    LoginPopupComponent,
    ScrollTopComponent,
    SearchPhoneComponent,
    SearchResultComponent,
    SplashScreenComponent,
    Subheader1Component,
    UserProfileComponent,
} from './layout';
import {PortletModule} from './content/general/portlet/portlet.module';
import {ErrorComponent} from './content/general/error/error.component';
import {TranslateModule} from '@ngx-translate/core';
import {ActivityMenuComponent} from './layout/activity-menu/activity-menu.component';
import {InfoVideoCheckComponent} from './layout/info-video-check/info-video-check.component';
import {NgxDocViewerModule} from 'ngx-doc-viewer';
import {OrderCommentComponent} from './components/order-comment/order-comment.component';
import {MatListModule} from '@angular/material/list';
import {QRCodeModule} from 'angular2-qrcode';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ToastComponent} from './components/toaster/toast/toast.component';
import {ToasterComponent} from './components/toaster/toaster.component';
import {BrandComponent} from './components/brand/brand.component';
import {FooterComponent} from './components/footer/footer.component';
import {AsideLeftComponent} from './components/aside/aside-left.component';
import {NgxPermissionsModule} from 'ngx-permissions';
import {ErrorPageComponent} from './components/error-page/error-page.component';
import {SubheaderComponent} from './components/subheader/subheader.component';
import {HeaderComponent} from "./components/header/header.component";
import {HeaderMobileComponent} from "./components/header/header-mobile/header-mobile.component";
import {MenuHorizontalComponent} from "./components/header/menu-horizontal/menu-horizontal.component";
import {TopbarComponent} from "./components/header/topbar/topbar.component";
import {LoadingBarModule} from "@ngx-loading-bar/core";
import {BaseComponent} from "./components/base/base.component";
import {StoreModule} from "@ngrx/store";
import {PermissionEffects, permissionsReducer, RoleEffects, rolesReducer} from "../../core/auth";
import {EffectsModule} from "@ngrx/effects";
import { DiscoutConfirmComponent } from './layout/discout-confirm/discout-confirm.component';


@NgModule({
    declarations: [
        ScrollTopComponent,
        ActionNotificationComponent,
        DeleteEntityDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertComponent,
        SearchPhoneComponent,
        SearchResultComponent,
        SplashScreenComponent,
        Subheader1Component,
        LanguageSelectorComponent,
        UserProfileComponent,
        CheckoutSuccessComponent,
        ErrorComponent,
        LoginPopupComponent,
        ActivityMenuComponent,
        InfoVideoCheckComponent,
        OrderCommentComponent,
        ToasterComponent,
        ToastComponent,
        BrandComponent,
        FooterComponent,
        AsideLeftComponent,
        ErrorPageComponent,
        SubheaderComponent,
        HeaderComponent,
        HeaderMobileComponent,
        MenuHorizontalComponent,
        TopbarComponent,
        BaseComponent,
        DiscoutConfirmComponent,
    ],
    exports: [
        PortletModule,
        NgxDocViewerModule,
        ScrollTopComponent,
        ActionNotificationComponent,
        DeleteEntityDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertComponent,
        SearchPhoneComponent,
        Subheader1Component,
        LanguageSelectorComponent,
        UserProfileComponent,
        CheckoutSuccessComponent,
        ErrorComponent,
        LoginPopupComponent,
        ActivityMenuComponent,
        InfoVideoCheckComponent,
        MatCheckboxModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatDatepickerModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatInputModule,
        OrderCommentComponent,
        ToasterComponent,
        SearchResultComponent,
        SplashScreenComponent,
        BrandComponent,
        FooterComponent,
        AsideLeftComponent,
        ErrorPageComponent,
        SubheaderComponent,
        HeaderComponent,
        HeaderMobileComponent,
        MenuHorizontalComponent,
        TopbarComponent,
        BaseComponent
    ],
    imports: [
        NgxDocViewerModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        PerfectScrollbarModule,
        CoreModule,
        PortletModule,
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatSelectModule,
        MatDialogModule,
        TranslateModule.forChild(),
        MatListModule,
        QRCodeModule,
        MatSlideToggleModule,
        NgxPermissionsModule.forChild(),
        LoadingBarModule,
        StoreModule.forFeature('roles', rolesReducer),
        StoreModule.forFeature('permissions', permissionsReducer),
        EffectsModule.forFeature([PermissionEffects, RoleEffects]),
    ]
})
export class PartialsModule {
}
