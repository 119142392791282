export const locale = {
  lang: 'hu',
  data: {
      COMMON: {
          PRICE: 'Price',
          MONTH: 'month',
          NO: 'No',
          YES: 'Yes',
          HEADQUARTER: 'Headquarter',
          CATEGORY_TYPES: {
              TITLE: 'Category types',
                TV: 'TV',
                TELE: 'TELE',
                BROADBAND: 'Broadband',
          }
      },
    INFO: {
      SEARCH: 'Keresés',
      SEARCHING: 'keres',
    },
    TRANSLATOR: {
      SELECT: 'Nyelv',
    },
    MENU: {
      NEW: 'új',
      ACTIONS: 'akció',
      CREATE_POST: 'Hozzon létre új rekordot',
      PAGES: 'Oldal',
      FEATURES: 'Funkciok',
      APPS: 'alkalmazások',
      DASHBOARD: 'Dashboard',
      CATALOG: 'Készülék kereső',
      ORDERS: 'Ajánlatok',
      ORDERS_VIEW: 'Böngésző',
      ADMINISTRATION: 'Adminisztrácio',
    },
    AUTH: {
      GENERAL: {
        OR: 'vagy',
        SUBMIT_BUTTON: 'Küldés',
        NO_ACCOUNT: 'Nincs fiók?',
        SIGNUP_BUTTON: 'Regisztráció',
        FORGOT_BUTTON: 'Elfelejtett jelszó',
        BACK_BUTTON: 'vissza',
        PRIVACY: 'Adatvédelmi',
        LEGAL: 'jogi',
        CONTACT: 'Kapcsolatba lépni',
      },
      LOGIN: {
        TITLE: 'Bejelentkezés',
        BUTTON: 'Bejelentkezés',
      },
      LOGOUT: {
          TITLE: 'Bejelentkezés',
          BUTTON: 'Bejelentkezés',
      },
      FORGOT: {
        TITLE: 'Elfelejtette a jelszavát?',
        DESC: 'Írja be e-mail címét a jelszó visszaállításához',
        SUCCESS: 'Fiókját visszaállítottuk.',
        SENT: 'kérlek ellenőrizd az e-mailed',
      },
      REGISTER: {
        TITLE: 'Regisztráció',
        DESC: 'Adja meg a részleteket a fiók létrehozásához',
        SUCCESS: 'Fiókját regisztrálták',
      },
      INPUT: {
        EMAIL: 'Email vagy Felhasználó azonosító',
        FULLNAME: 'Teljes név',
        PASSWORD: 'jelszó',
        CONFIRM_PASSWORD: 'jelszó megerősítése',
        USERNAME: 'felhasználónév',
      },
      VALIDATION: {
        INVALID: '{{name}} érvénytelen',
        REQUIRED: '{{name}} kötelező',
        MIN_LENGTH: '{{name}} minimális hossza {{min}}',
        AGREEMENT_REQUIRED: 'szükséges elfogadni a feltételeket.',
        NOT_FOUND: 'kért {{name}} nem található',
        INVALID_LOGIN: 'a hitelesítő adatok helytelenek',
        REQUIRED_FIELD: 'Kötelező ',
        MIN_LENGTH_FIELD: 'Minimális hosszúság:',
        MAX_LENGTH_FIELD: 'A maximális hosszúság:',
        INVALID_FIELD: 'nem érvényes',
      },
      SERVER_ERRORS: {
        incorrect_password_or_email: 'Hibás jelszó vagy e-mail',
        ip_not_allowed: 'Az Ön IP-címe nem engedélyezett.',
        wrong_password: 'Rossz jelszó',
        missing_user: 'Nincs felhasználó',
        too_many_requests: 'túl sok kérés',
        invalid_credentials: 'Érvénytelen hitelesítő adatok',
      },
    },
    USER: {
      HELLO: 'Szia',
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'A kiválasztott rekordok számítanak: ',
        ALL: 'minden',
        SUSPENDED: 'Felfüggesztett',
        ACTIVE: 'aktív',
        FILTER: 'Szűrő',
        BY_STATUS: 'állapot szerint',
        BY_TYPE: 'típus szerint',
        BUSINESS: 'Üzleti',
        INDIVIDUAL: 'Egyedi',
        SEARCH: 'Keresés',
        IN_ALL_FIELDS: 'minden területen',
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'ügyfelek',
        CUSTOMERS_LIST: 'Ügyféllista',
        NEW_CUSTOMER: 'Új ügyfél',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Törölje az ügyfelet',
          DESCRIPTION: 'Biztos benne, hogy véglegesen törli az ügyfelet?',
          WAIT_DESCRIPTION: 'Ügyfél törlése ...',
          MESSAGE: 'Az ügyfelet töröltük',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Törölje az ügyfeleket',
          DESCRIPTION: 'Biztos benne, hogy véglegesen törli az ügyfeleket?',
          WAIT_DESCRIPTION: 'Ügyfelek törlése ...',
          MESSAGE: 'A kiválasztott vásárlókat töröltük',
        },
        UPDATE_STATUS: {
          TITLE: 'Az állapot frissült a kiválasztott ügyfelek számára',
          MESSAGE: 'A kiválasztott vevői állapot frissült',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Az ügyfél frissült',
          ADD_MESSAGE: 'Az ügyfelet létrehozták',
        },
      },
    },
    PRICING_INPUT: {
      TITLE: 'Árképzési bemenet',
      PURCHASE_PRICE: 'Vételár',
      SALES_PRICE: 'Értékesítési ár',
      ALL_VALUE_FILTER: 'Összes',
      CONTRACT_NAME: 'Tarifacsomag',
      TABLE: {
        PROVIDER_NAME: 'Szolgáltató neve',
        NAME: 'Név',
        DISCOUNT: 'Kedvezmény',
        SUBVENTION: 'Szubvenció',
        IMEI_FIRST: 'IMEI 1',
        IMEI_SECOND: 'IMEI 2',
        MANUAL_PRICE_PER_MONTH: 'Manuálisan beállított ár havonta',
        MANUAL_PRICE: 'Manuálisan beállított ár',
        TO_PAY: 'fizetendő',
        COST: 'Költség',
        PRIORITY: 'Prioritás',
        MORE: 'Több',
        MODIFY: 'Módosít',
        SAVE: 'Mentés',
        CANCEL: 'Töröl',
        TOTAL_COMMISSION: 'jutalék',
        PURCHASE_PRICE: 'Vételár',
        TOTAL: 'Teljes',
      },
      ERRORS: {
        ALLOWED_INPUT: 'Megengedett',
      },
    },
    CONTRACT_INPUT: {
      TITLE: 'Szerződéses inputok',
      ARTICLE_FILTER: 'Cikk név szűrő',
      PROVIDER_NAME: 'Szolgáltató neve',
      MONTH_LENGTH: 'Hónap hossza',
      ADD_NEW: 'Új hozzáadása',
      TABLE: {
        MANUFACTURER: 'Gyártó',
        ARTICLE_NAME: 'Cikk neve',
        MONTH: 'Hónap',
        DISCOUNT: 'Kedvezmény',
        MONTHLY_COST: 'Havidij',
        DATA: 'adat',
        FEE_IN_STORE: 'Díj a boltban',
        FEE_AMOUNT: 'Díj összegét',
        FEE_ARTICLE: 'Díjcikk',
        STAFF_BONUS: 'Személyzeti bónusz',
        MODIFY: 'Módosít',
        EDIT: 'Szerkesztés',
      },
      EDIT_PAGE: {
        TITLE: 'Szerződés szerkesztése',
        SEARCH_INFO: {
          TITLE: 'Információk keresése',
          PROVIDER_NAME: 'Szolgáltató neve',
          CONTRACT_TYPE: 'Szerződéstípus',
          PRODUCT_SEARCH: 'Termékkeresés',
          UNDO: 'Visszavonás',
          SAVE: 'Mentés',
        },
        PRODUCT_INFO: {
          TITLE: 'Termék információ',
          PRODUCT_GROUP: 'Termékcsoport',
          MANUFACTURER: 'Gyártó',
          ARTICLE_NUMBER: 'Cikkszám',
          ARTICLE_NAME: 'Cikk neve',
          CONTRACT_LENGTH: 'A szerződés időtartama',
          DISCOUNT: 'Kedvezmény',
          MONTHLY_COST: 'Havidij',
          DATA_INCLUDED: 'Adatokat tartalmaz',
          CONTRACT_TYPE: 'Szerződéstípus',
          EXTRA_SIM_FLAG: 'Extra sim zászló',
          ONLINE_VISIBLE: 'Aktiv online',
        },
        CALCULATION_INFO: {
          TITLE: 'Számítás	',
          TOTAL_COMMISSION: 'Teljes jutalék',
          PERCENT_COMMISSION: '% jutalék',
          CALCULATED_COMMISSION: 'Megtartott összeg',
          CALCULATION_MODEL: 'Számítási modell',
        },
        CONNECTED_ARTICLES: {
          TITLE: 'Összekapcsolt cikkek',
          STARTING_FEE_IN_STORE: 'Kezdő díj a boltban',
          STARTING_FEE_AMOUNT: 'Kezdő díj összege',
          STARTING_FEE_ARTICLE: 'Kezdő díj cikk',
          ADDITIONAL_SUBVENTION: 'További támogatás',
          SUBVENTION_COMMISSION: 'Támogatási bizottság',
          SUBVENTION_ARTICLE: 'Subvention cikk',
        },
        INCENTIVES_INFO: {
          TITLE: 'Ösztönzők',
          STAFF_BONUS: 'Személyzeti bónusz',
        },
      },
    },
    ORDER: {
      TITLE_LIST: 'Rendelési lista',
      TITLE_EDIT: 'Rendelés kezelése',
      TITLE_NEW: 'új rendelés',
      // New contract
      TITLE_TYPE: 'Új szerződés',
      TITLE_COMMENT: 'megjegyzések',
      TITLE_LOGGING: 'Szerkesztési elözmények',
      BTN_CANCELLED: 'Törlés',
      BTN_RESET: 'visszavon',
      BTN_BACK: 'vissza',
      BTN_SAVE: 'Mentés',
      BTN_DOWNLOAD: 'Letöltés',
      BTN_SAVE_COMMENT: 'megjegyzés mentése',
      BTN_NEW: 'Új rendelés',
      BTN_EDIT: 'Rendelés módosítása',
      BTN_DELETE: 'Rendelés törlése',
      BTN_PREVIEW_INSURANCE_PROPOSAL: 'A biztosítási javaslat előnézete',
      BTN_PREVIEW_INSURANCE_FINAL_DOCUMENT: 'Aktivált biztosítási szerződés',
      INSURANCE_PROPOSAL_NOT_RECEIVED:
        'Biztosítási dokumentum még nem érkezett meg',
      INSURANCE_FINAL_PROPOSAL_NOT_RECEIVED:
        'A végleges biztosítási dokumentum még nem érkezett meg',
      CONTINUE_ORDER: 'Folytassa a megrendelést',
      ORDER_UUID: 'Rendelési uuid',
      TEMPORARY_ORDER_CREATED: 'Ideiglenes rend jött létre',
      TEMPORARY_ORDER_UPDATED: 'Ideiglenes rendelés-frissítő',
      HUNGARY: {
        NEXT_BUTTON: 'Tovább',
        BACK_BUTTON: 'Vissza',

        ORDER_VODAFONE_STATUS: 'Vodafone szerződés státusz',
        BUDAPEST_BANK_STATUS: 'Budapest Bank hitelstátusz',
        AEGON_INSURACE_STATUS: 'Aegon Garancia Plusz státusz',

        SIM_ACTIVATION: {
          ENTER:
            'Kérjük, adja meg újra a SIM-kártya számát a SIM-kártya aktiválásának ellenőrzéséhez',
          ERROR:
            'A megadott SIM-kártya száma nem számolja a tárolt SIM-kártya számát. Kérjük, ellenőrizze a helyes SIM-kártya számát az új szám kétszeri beírásával.',
        },

        STATUSES: {
          RECEIVED: 'A megrendelés beérkezett',
          REGISTRATION_IN_PROGRESS: 'A regisztráció folyamatban van',
          REGISTRATION_APPROVED: 'A regisztráció jóváhagyva',
          REGISTRATION_DECLINED: 'A regisztráció elutasítva',
          INCORRECT_DETAILS: 'Hibás adatok',
          PENDING: 'Függőben levő',
          OFFER_RECEIVED: 'Az ajánlat beérkezett',
          INSURANCE_APPROVED: 'Biztosítás jóváhagyva',
          INSURANCE_DECLINED: 'A biztosítás csökkent',
        },
        CURRENT_OPERATOR_TITLE_SHORT: 'Jelenlegi szolgáltatás (nem kötelező)',
        CURRENT_OPERATOR_TITLE: 'Válassza ki a jelenlegi mobilszolgáltatóját',
        CURRENT_OPERATOR_NAME: 'Szolgáltató',
        CURRENT_OPERATOR_MONTHLY_AMOUNT: 'havi összeg',
        CURRENT_OPERATOR_CONTRACT_TYPE: 'Feltöltőkártyás Flotta Előfizetés',

        SUMMARY_BLOCK: {
          DEVICE: 'Készülék',
          TOTAL_PRICE: 'Teljes ár',
          PER_MONTH: 'Havonta',
          DEVICE_DISCOUNT: 'készülék kedvezmény',
          CONTRACT: 'Vodafone előfizetés',
          FINANCE: 'Hitel',
          FINANCE_BLOCK: 'Részletfizetés',
          FINANCE_TO_PAY: 'Önerö',
          TOTAL_AMOUNT: 'Teljes összeg',
          FINANCE_TOTAL_AMOUNT: 'Finanszírozandó összeg',
          INSTALLMENT_PAYMENT: 'Szerződéskötéskor fizetendő',
          INSURANCE: 'Garancia kiterjesztés',
          TOTAL_21: 'Havi költségek 21 hónapon keresztül:',
          TOTAL_3: 'Havi költségek a 21 hónapot követő 3 hónapban:',
          TOTAL_22: 'Részletfizetés és előfizetés havidíj 22 hónapon keresztül',
          TOTAL_2: 'Előfizetés havidíj a 22 hónapot követő 2 hónapban',
          SUBTOTAL: 'Összesen',
          SUBTOTAL_DEVICE: 'Fizetendő Vodafone csomagajánlatban',
          SUBTOTAL_INSURANCE: 'Egyéb összesen',
          TO_PAY: 'Önerö',
          ACCESSORIES: 'Kiegészítők',
          ADMINISTRATION_FEE: 'Adminisztrációs díj',
        },
        INSURANCE_DOCUMENT: {
          TITLE: 'szerződés',
          TEXT: 'A továbblépés előtt ismertesd a vásárlóval az adatkezelési tájékoztatót, igény esetén nyomtatott formában is add át részére. Ha a vásárló hozzájárul az adatkezeléshez, kattints a "Következő" gombra!',
          NEXT: 'Következő',
          AGREEMENT:
            'A Media Markt, a Vodafone, a Biztosító és a Budapest Bank együttes elhatározása, hogy a Media Markt-nál történő készülék vásárlás esetén a Önnek lehetőséget biztosítsanak arra, hogy a készülékvásárlással egyidejűleg, illetve azt követően Kapcsolódó szolgáltatásokat is igénybe tudjon venni.\n' +
            '\n' +
            'A Media Markt-nál történő készülék vásárlás- és Kapcsolódó szolgáltatás igénylés esetén az Ön személyes adatai a Media Markt Verticula programjában kerülnek rögzítésre, majd innen a kiválasztott szolgáltatást nyújtó adatkezelő részére továbbításra, így Önnek nem szükséges több helyszínen, többször megadnia személyes adatait, mindez egy helyen és időben megvalósítható.\n' +
            '\n' +
            'Mindezekre tekintettel a Media Markt és az igénybe vett Kapcsolódó szolgáltatások nyújtói a Verticula programban kezelt adatok tekintetében közös adatkezelőknek minősülnek. Fontos, hogy személyes adataihoz minden esetben csak azon adatkezelők férnek hozzá, akiknek Kapcsolódó szolgáltatását igénybe kívánja venni, és minden adatkezelő csak azon adataihoz fér hozzá, amelyek az általa nyújtott szolgáltatás nyújtásához elengedhetetlenül szükségesek. Az egyes szolgáltatókkal történő szerződéskötés során a Szolgáltatók külön tájékoztatást nyújtanak Önnek a Verticula programon túli adatkezelésről, mely már kifejezetten a Szolgáltatóval fennálló jogviszonyára vonatkozó adatkezelésre tér ki.\n' +
            '\n' +
            'A teljeskörű tájékoztatást a közös adatkezelésről szóló tájékoztatónkban olvashatja, melyet kérése esetén máris nyomtatunk.',
        },
        ACTIVITY_MENU: {
          TITLE: 'Automatikus kijelentkezés figyelmeztetés.',
          TEXT_ONE: '10 perc múlva kijelentkezik',
          TEXT_TWO: 'inaktivitás miatt. Szeretne bejelentkezve maradni?',
          TEXT_THREE: 'A folytatáshoz nyomja meg a "megerősítés" gombot',
          STAY: 'Maradj bejelentkezve',
          LOG_OUT: 'Kijelentkezés',
        },
        INFOVIDEO: {
          BACK_BUTTON: 'Vissza',
          NEXT_BUTTON: 'Tovabb',
          CONFIRM_BUTTON: 'Elfogadom',
          ORDER_TITLE: 'Rendelesi összefoglalás',
          ORDER_TEXT_HEADER: '"Nyilatkozat nélküli infovideo:',
          ORDER_TEXT:
            'Kedves ügyfelünk köszönjük, hogy meghallgattad Kollégánkat, az elöbbi összefoglaló oldalon összegeztük neked azt amiről beszéltetek.A következő kb X percben szeretnénk tájékoztatni minden olyan feltételről téged, ami elengedhetetlen ahhoz, hogy szerződést kössünk egymással. Célunk, hogy minden szükséges feltételt megismerj a lehető legegyszerűbb módon. Ehhez nyújunk segítséget a következő rövid videókkal. Minden video egy nyilatkozat megismerését teszi lehetővé, így videónként szükséges döntened majd. Lesznek olyanok ahol dönthesz úgy, hogy igénybe kívánod-e venni az adott lehetőséget, azonban az itt egy piktogram) ilyen jelölésselellátott videók esetében az IGEN válasz jelölése nélkül a szerződés nem köthető meg, így, ha a videó megtekintését követően további kérdésed merülne fel ezzel a gombbal (piktogramm) ismét megtekintheted azt. Ha a nyilatkozat elolvasását követően mégis kérdésed maratt fordulj bátran kollégáinkhoz."',
          VIDEO_TITLES: [
            '1. Infovideo, mint digitális segítség',
            '2. Csomagajánlatunk (N/A)',
            '3. Választott Vodafone előfizetés (N/A)',
            '4. Magánszemélyként szerződhetek',
            '5. Szükséges dokumentumok',
            '6. Roaming',
            '7. Szerződésmásolat',
            '8. Elektronikus értesítések',
            '9. Felmondási jog',
            '10. Adatvédelmi tájékoztató',
            '11. Hívásrészletező, személyes adatok',
            '12. Egyedi értékhatár',
            '13. Nyilatkozat teljesköru tajekoztasrol',
            '14. Szerződés modositas',
            '15. Szerződés idötartamok',
            '16. Hívásrészletező és tudakozó',
            '17. Direkt marketing hozzájárulás',
            '18. Helymeghatározási adatok',
          ],
          UNDER_VIDEO_TEXTS: [
            'Nyilatkozom, hogy az egyedi Előfizetői Szerződésben foglaltakat - különösen az abban foglalt hivatkozásokat - megismertem és tudomásul veszem. Tudomásul veszem, hogy az Előfizetői Szerződés az egyedi előfizetői szerződésből és az ÁSZF-ből áll, a szolgáltatással kapcsolatos kérdésekben elsősorban az egyedi előfizetői rendelkezések, másodsorban az ÁSZF rendelkezései az irányadóak. Nyilatkozom, hogy az Általános szerződési feltételeket (ÁSZF) megismertem és tudomásul veszem, hogy az az Előfizetői Szerződés részét képezi. Nyilatkozom, hogy az Egyéb Szolgáltatások Szerződési Feltételeit (ESZSZF) megismertem és tudomásul veszem, hogy az az Előfizetői Szerződés részét képezi.',
            'Nyilatkozom, hogy az Előfizetői Szerződés megkötése során a Szolgáltató teljes körű tájékoztatást nyújtott az érvényes barangolási díjakról és a szabályozott barangolásos adatátviteli szolgáltatások díjazására vonatkozóan',
            'Nyilatkozom, hogy az Előfizetői Szerződés megkötése előtt megkaptam a Szolgáltatótól a tájékoztatást arról, hogy az Előfizetői Szerződés fennállása alatt, vagy annak megszűnését követően az Eht. 143. § (2) bekezdése szerinti elévülési időn belül évente egyszer ingyenesen kérhetem az Előfizetői Szerződés általam megjelölt időpontjában hatályos tartalmáról szóló, legalább az Eszr. 11. § (1) bekezdés szerinti elemeket tartalmazó dokumentum átadását.',
            'Nyilatkozom, hogy a Szolgáltató értesítési kötelezettségének - beleértve a felmondást is - az általam megadott elérhetőségeken az Eht. 144. § (4) bekezdés a)-c) pontjában foglaltaknak megfelelően elektronikus úton vagy az elektronikus hírközlési szolgáltatás jellegéhez igazodó módon (különösen: SMS, MMS, telefonhívás) is eleget tehet.',
            'Tájékoztatást megkaptam, hogy az előfizetői szerződésben megjelölt igénybevételi helyen a szolgáltatás minőségének hiányosságaival kapcsolatos felmondási jog gyakorlásának lehetőségéről, feltételeiről és következményeiről az ÁSZF 12.4.2.1(d) és 12.6.9 pontban foglaltak alapján az alábbiak szerint: Az ÁSZF 12.4.2.1. (d) rendelkezése jelenleg: "Vezeték nélküli internet (azaz mobil internet hozzáférési), valamint mobil radiotelefon szolgáltatásra kötött Előfizetői Szerződés esetén Előfizető a szolgáltatás nyújtásának megkezdésétől számított 14 (tizennégy) napon belül a lenti 12.6.9. pontban meghatározott jogkövetkezményekkel azonnali hatállyal felmondhatja, ha az Előfizetői Szerződés további teljesítéséhez fűződő érdeke azon okból szűnt meg, hogy az Előfizetői Szerződésben megjelölt igénybevételi helyen (cím, annak hiányában helyrajzi szám) a Szolgáltatás minősége nem felel meg az Előfizetői Szerződésben foglaltaknak, vagy a Szolgáltatás nem vehető igénybe. A felmondási jog gyakorlásának lehetőségéről, feltételeiről és következményeiről Szolgáltató köteles igazolható módon tájékoztatni az Előfizetőt az Előfizetői Szerződés megkötésével egyidejűleg." Az ÁSZF 12.6.9 rendelkezése jelenleg: "A 12.4.2.1. (d) pontban foglalt felmondás esetén a Szolgáltató csak a szerződéskötéstől a felmondásig eltelt időre járó arányos havidíist valamint fordolomarínunc díiat tartalmazó Coldáltatác acatáhon 27 Flőfizető által tánvlodocon folhasznált fordalom díiát vadu a',
            'Előfizető kijelentem, hogy a Szolgáltatótól az Eht. 159. (1) bekezdésében foglaltakra vonatkozó adatvédelmi tájékoztatást megkaptam, erről részletes információt a ÁSZF 3. számú melléklete tartalmaz.',
            'Tudomásul veszem, hogy a Szolgáltató a 2/2015. (III. 30.) NMHH rendeletben előírt kötelezettségének eleget téve felhívta figyelmemet arra, hogy a részletes számlainformációt tartalmazó kimutatással más felhasználók személyes adatainak birtokába is juthatok. Ezeknek megismerésére csak akkor vagyok jogosult, ha a készüléket kizárólag én használom vagy telefonkészülékem használói - a tájékoztatásukat követően - felém tett nyilatkozattal ehhez hozzájárultak.',
            'Tudomásul veszem, hogy a Szolgáltató a szolgáltatás tekintetében az igénybevétel korlátjaként összeghatárokat, vagy hívás-, üzenet-, adatmennyiséget határozott meg, és ezek túllépése esetén az Eht 137. § (1) bekezdés d) pontja szerint korlátozással élhet.',
          ],
          INFOVIDEO_LOGIN_TITLE: 'Infovideo Bejelentkezés',
          COMPLETED_ALL_STEPS: 'Minden nyilatkozatát rögzítettük!\n Köszönjük!',
        },

        // First step

        FIRST_TITLE: 'Előzetes adatok',
        STORE_CODE: 'Kereskedő kód',
        STORE_NAME: 'Kereskedő neve',
        FINANCE_ADMIN_CODE: 'Hitelügyintéző kódja',
        MARKER: 'Jelölő adat',
        CREDIT_TYPE: 'Hitel típusa',
        CONFIRMATION: 'Jóváhagyásba',
        CMBET: 'Van otthon vezetékes telefon',
        CMBSZZ: 'Bankszámlával igazoly jövedelem?',
        CMBB: 'BB-s bankszámlával igazolt jövedelem?',
        CMBMNJ: 'Munkabér levonásához nem járul hozzá',
        BNPL: 'BNPL',
        CUSTOMER_TYPE_NAME: 'Ügyféltípus neve',
        CREDIT_INTERMEDIARY_AGENT: 'Hitelközvetítő ügynökkód',
        CUSTOMER_WAITING_FOR_REVIEW: 'Ügyfél megvárja a bírálatot?',
        DOES_CLIENT_ACT_ON_ITS_OWN_BEHALF: 'Ügyfél saját nevében jár el?',

        // Second step

        SECOND_TITLE: 'Nyilatkozat adatok',
        HAVE_THE_PUBLIC_CHARGES_BEEN_PAID:
          'Tudomása szerint megfizetésre kerültek a közterhek a jövedelme után?',
        ADD_INCOME: 'Új jövedelem',
        TYPE_OF_INCOME: 'Jövedelem tipusa',
        METHOD_OF_PROOF_OF_INCOME: 'Jövedelemigazolás módja',
        DATE_OF_ISSUE_CERTIFICATE: 'Jövedelemigazolás kiállitásának kelte',
        AMOUNT_OF_MONTHLY_NET_INCOME: 'Havi nettó jövedelem összege',
        CHANGE_COLUMN: 'Szerkesztés',
        MODIFY_BUTTON: 'Módosít',
        REMOVE_BUTTON: 'Eltávolítás',
        ARE_YOU_GUARANTOR:
          'Hozzátartozója által felvett pénzügyi lízingben kezes-e?:',
        AMOUNT_OF_MONTHLY_LEASE:
          '30 napon belül le nem járó, pénzügy lízingszerződésből fakadó havi lízingdíj összege',
        HAS_LOAN_COMBINED_WITH_HOME:
          'Van-e lakás-előtakarékosségi szerződéssel (LTP) kombinált hitele, amely esetén a LTP szerződést nem mondhatja vissza egyoldalúan?:',
        AMOUNT_OF_MONTHLY_FEE_ARISING:
          '30 napon belül le nem járó, LTP szerződésből fakadó havi díj összege:',
        HAS_LOAN_COMBINED_WITH_LIFE:
          'Van-e életbiztosítással kombinált hitele, amely esetén az életbiztosítási szerződést nem mondhatja vissza egyoldalúan?:',
        AMOUNT_OF_MONTHLY_PREMIUM_ARISING:
          '30 napon belül le nem járó, Életbiztosítási szerződésből fakadó havi díj összege',
        CLAIMING_TOTAL_MONTHLY_NET_INCOME: 'Igényõ összes have nettó jövedelem',
        NET_MONTHLY_INCOME_OF_CLAIMING_HOUSEHOLD:
          'Háztartás havi nettó jövedelme (az igénylőén kívül)',
        TOTAL_INCOME: 'Összes jövedelem',
        // Third step

        THIRD_TITLE: 'Személyes adatok',
        ID_CARD_NUMBER: 'Azonosító okmány száma',
        IDENTITY_CARD_TYPE: 'Igazolvány típusa',
        DATE_OF_ISSUE: 'Azonosító okmány kiállításának dátuma',
        GENDER: 'Neme',
        COUNTRY_OF_CITIZENSHIP: 'Állampolgárság országa',
        TITLE: 'Titulus',
        FIRST_NAME: 'Név / Keresztnév',
        LAST_NAME: 'Név / Vezeték név',
        BIRTH_FIRST_NAME: 'Születési név / Keresztnév',
        BIRTH_LAST_NAME: 'Születési név / Vezetéknév',
        MOTHERS_BIRTH_FIRST_NAME: 'Anyja neve / Keresztnév',
        MOTHERS_BIRTH_LAST_NAME: 'Anyja neve / Vezetéknév',
        DATE_OF_BIRTH: 'Születési idö',
        PLACE_OF_BIRTH: 'Születési hely',
        DOES_CUSTOMER_CONSENT_TO_THE_BANK:
          'Hozzájárul az ügyfél ahhoz, hogy a Bank a hitelbírálathoz kapcsolódóan lekérdezze a pozitív adatait?',
        DOES_CUSTOMER_CONSENT_TO_ANOTHER_BANK:
          'Hozzájárul az ügyfél ahhoz, hogy más bank átvegye az adatait a pozitív KHR-ből?',
        DOES_CLIENT_CONSENT_THE_POSITIVE:
          'Hozzájárul-e az ügyfél ahhoz, hogy a pozitív KHR a szerződés megszűnését követően 5 évig kezelhesse az adatait?',
        PLACE_OF_DECLARATION: 'Nyilatkozat helye',
        DATE_OF_DECLARATION: 'Nyilatkozat dátuma',
        MARTIAL_STATUS: 'Családi állapot',
        EDUCATION: 'Végzettség',
        OCCUPATION_TYPE: 'Foglalkozás típusa',
        NUMBER_OF_SEARCH_ENGINES: 'Keresök száma',
        NUMBER_OF_DEPENDENTS: 'Eltartottak száma',

        // Fourth step

        FOURTH_TITLE: 'Lakcim adatok',
        PERMANENT_ZIP: 'Állandó lakcím / Irányítószám',
        PERMANENT_CITY: 'Állandó lakcím / Város',
        PERMANENT_NAME_OF_PUBLIC_AREA: 'Állandó lakcím / Közterület neve',
        PERMANENT_NAME_OF_PUBLIC_SPACE: 'Állandó lakcím / Közterület jellege',
        PERMANENT_HOUSE_NUMBER: 'Állandó lakcím / Házszám',
        PERMANENT_BUILDING: 'Állandó lakcím / Épület',
        PERMANENT_STAIRCASE: 'Állandó lakcím / Lépcsőház',
        PERMANENT_FLOOR: 'Állandó lakcím / Emelet',
        PERMANENT_DOOR: 'Állandó lakcím / Ajtó',

        RESIDENCE_ZIP: 'Tartózkodási lakcím / Irányítószám',
        RESIDENCE_CITY: 'Tartózkodási lakcím / Város',
        RESIDENCE_NAME_OF_PUBLIC_AREA: 'Tartózkodási lakcím / Közterület neve',
        RESIDENCE_NAME_OF_PUBLIC_SPACE:
          'Tartózkodási lakcím / Közterület jellege',
        RESIDENCE_HOUSE_NUMBER: 'Tartózkodási lakcím / Házszám',
        RESIDENCE_BUILDING: 'Tartózkodási cím / Épület',
        RESIDENCE_STAIRCASE: 'Tartózkodási lakcím / Lépcsőház',
        RESIDENCE_FLOOR: 'Tartózkodási lakcím / Emelet',
        RESIDENCE_DOOR: 'Tartózkodási lakcím / Ajtó',
        RESIDENCE_PHONE_NUMBER: 'Tartózkodási lakcím / Telefonszám',
        RESIDENCE_PO_BOX: 'Tartózkodási lakcím / Postafiók',

        COPY_FROM_PERMANENT_BUTTON: 'Állandó cím másolása',

        PHONE_NUMBER: 'Telefonszám',
        LANDLINE_PHONE_NUMBER: 'Vezetekes telefonszám',
        PHONE_FOR_SHOPPING: 'Mobiltelefonszám internetes vásárláshoz',
        INTERNETBAN_NUMBER: 'Mobiltelefonszám internetes vásárláshoz',
        MOBILLBANK_NUMBER: 'Mobilbank telefonszám',
        MOBIL_PHONE_NUMBER: 'Mobil telefonszám',
        EMAIL: 'Email',
        TYPE_OF_MOBILE_CONTRACT: 'Mobilszerződés típusa',
        WHEN_DID_CONTRACT_WITH_MOBILE:
          'Mikor szerződött a mobilszolgáltatóval?',
        HOW_LONG_CURRENT_ADDRESS: 'Mikor költözött jelenlegi lakcímére? ',
        HOW_LONG_PROPERTY: 'Tulajdon:',

        // Fifth step

        FIFTH_TITLE: 'Kártya adatok',
        CARD_NAME: 'Kártyán szereplő név',
        PASSWORD: 'Jelszó',
        NEED_MOBILE_BANK: 'Igényel mobilbankot?',
        COBRAND_ID: 'Cobrand azonosító',

        // Sixth step

        SIXTH_TITLE: 'Munkáltató adatok',
        SELF_EPLOYEED: 'Egyéni vállalkozó-e?',
        BUSINESS_CARD_NUMBER: 'Vállálkózói igazolvány száma (e.v. Esetén)',
        UJKM_CODE: 'Kódok oldal / ÚJKM kód',
        POSITION: 'Beosztás',
        EMPLOYER: 'Cégforma',
        CURRENT_JOB: {
          COMPANY_NAME: 'Jelenlegi munkahely / Cég neve',
          COMPANY_REGISTRATION_NUMBER: 'Jelenlegi munkahely / Cégjegyzékszám',
          TAX_NUMBER: 'Jelenlegi munkahely / Adószám',
          POSTCODE: 'Jelenlegi munkahely / Irányítószám',
          CITY: 'Jelenlegi munkahely / Város',
          PUBLIC_AREA: 'Jelenlegi munkahely / Közterület neve',
          PUBLIC_SPACE: 'Jelenlegi munkahely / Közterület jellege',
          HOUSE_NUMBER: 'Jelenlegi munkahely / Házszám',
          BUILDING: 'Jelenlegi munkahely / Épület',
          STAIRCASE: 'Jelenlegi munkahely / Lépcsőház',
          FLOOR: 'Jelenlegi munkahely / Emelet',
          DOOR: 'Jelenlegi munkahely / Ajtó',
          PHONE_NUMBER: 'Jelenlegi munkahely / Telefonszám',
          EMPLOYER_EMAIL_ADDRESS: 'Munkáltató e-mail cím',
          SECTOR: 'Jelenlegi munkahely / Szektor',
          OCCUPATION: 'Jelenlegi munkahely / Foglalkozás',
          WORK_PHONE_NUMBER: 'Jelenlegi munkahely / Munkahelyi telefonszám',
          SIDE: 'Jelenlegi munkahely / Mellék',
          BEGINNING_OF_EMPLOYMENT: 'Munkaviszony kezdete',
          END_OF_EMPLOYMENT: 'Munkaviszony vége',
          FIXED_EMPLOYMENT: 'Határozott munkaviszony',
        },
        PREVIOUS_JOB: {
          COMPANY_NAME: 'Előző munkahely / Cég neve',
          POSTCODE: 'Előző munkahely / Irányítószám',
          CITY: 'Előző munkahely / Város',
          PUBLIC_AREA: 'Előző munkahely / Közterület neve',
          PUBLIC_SPACE: 'Előző munkahely / Közterület jellege',
          HOUSE_NUMBER: 'Előző munkahely / Házszám',
          BUILDING: 'Előző munkahely / Épület',
          STAIRCASE: 'Előző munkahely / Lépcsőház',
          FLOOR: 'Előző munkahely / Emelet',
          DOOR: 'Előző munkahely / Ajtó',
          PHONE_NUMBER: 'Előző munkahely / Telefonszám',
          BEGINNING_OF_EMPLOYMENT: 'Munkaviszony kezdete',
          END_OF_EMPLOYMENT: 'Munkaviszony vége',
        },
        NAME_OF_CONTACT_PERSON: 'Kapcsolattartó neve',
        NUMBER_PHONE: 'Telefonszám',
        ATTACHMENT: 'Mellék',
        EMPLOYER_PAYMENT_ACCOUNT_NUMBER: 'Munkáltató fizetési számlaszáma',
        PRIMARY_PRODUCER_CARD_NUMBER: 'Őstermelői igazolvány száma',

        // Seventh step

        SEVENTH_TITLE: 'Hitelfedezeti biztosítás',
        TYPE_OF_INSURANCE: 'Biztosítás típusa',
        NEEDS_THE_INCURANCE: 'Igényli-e',
        CONSTRUCTION: 'Konstrukció',
        DESIGNATION: 'Konstrukció megnevezése',
        OTHER_GROUNDS_FOR_REFUSAL: 'Egyéb elutasítási ok',

        // Eigth step

        EIGHT_TITLE: 'Tranzakció',
        MATURITY: 'Futamidő',
        MY_STRENGTH: 'Sajáterő',
        PURCHASE_PRICE: 'Vételár',
        LOAN_AMOUNT: 'Hitelösszeg',
        BANK_ACCOUNT_NUMBER: 'Bankszámlaszám',
        PRICE_WARRANTY_PRODUCTS: 'Garanciális termékek ára:',
        CREDIT_LINE_REQUIRED: 'Igényelt hitelkeret',
        PAYMENT: 'Fizetési mód',
        INSTALLMENT: 'Törlesztőrészlet',
        TRANSACTION_ID: 'Tranzakció azonosító',
        TYPE_OF_TRANSACTION: 'Tranzakció típusa',
        CUSTOMER_TYPE_CODE: 'Ügyféltípus kódja',
        TRANSACTION_CUSTOMER_TYPE_CODE: 'Tranzakció Ügyféltípus kódja',
        VOUCHER_AMOUNT: 'Utalvány összege',
        PRODUCT_LIST_NAME: 'Termék lista / Megnevezés',
        PIECE: 'Darab',
        UNIT_PRICE: 'Egységár',
        EAN: 'EAN kód',
        AGREEMENT: {
          DOWNLOAD: 'Letöltés',
          PRINT: 'Nyomtatás',
          LOADING_ERROR:
            'Ha nem látja a dokumentumot, próbálja meg újra megnyitni',
        },
        NO_COMMENT: 'Nem nyilatkozik',
      },
      REPORT: {
        DOWNLOAD_REPORT: 'Riport letöltese',
        FILTER: 'szurö',
        SALES_PERSON_ID: 'Értékesítő azonositoja',
        DATE: 'Datum',
        OPERATOR: 'Szolgaltato',
        TABLE: {
          OPERATOR: 'szolgaltato',
          DATE: 'Datum',
          STORE_ID: 'Aruház azonosito',
          SALES_PERSON_ID: 'Értékesítő azonositoja',
          ORDER_NUMBER: 'Rendelési szám',
          CONTRACT: 'Tarifacsomag',
          PHONE: 'Készulék',
          TOTAL_COMMISSION: 'Összes jutalék',
          TOTAL_PURCHASE: 'beszerzési ár',
          TOTAL_TO_PAY: 'Összesen fizetendö',
          GROSS_PROFIT: 'Nyereség',
          BONUS: 'Bonus',
          TOTAL: 'Összesen',
        },
      },
      FORM: {
        PERMANENT_ADDRESS: 'Állandó lakcím',
        RESIDENCE_ADDRESS: 'Tartózkodási hely',
        STATE: 'Csomagajánlat státuszs',
        CUSTOMERID: 'Ügyfél-azonosító',
        PHONENUMBER: 'Telefonszám (formátum: 701234567)',
        ADDRESSCARDNUMBER: 'Lakcímkártya',
        BIRTHDATE: 'Születési dátum',
        FIRSTNAME: 'Keresztnév',
        LASTNAME: 'Vezetéknév',
        ADDRESS: 'Cím',
        ZIPCODE: 'Irányítószám',
        CITY: 'Város',
        EMAIL: 'Email',
        ACCOUNTBANK: 'Bankszámla',
        BANKNAME: 'Bank neve',
        IBAN: 'IBAN',
        CANCELLATIONREASON: 'Törles oka',
        DEVICE: 'Készülék',
        SUBVENTION: 'Artámogatás',
        CONTRACT: 'Tarifacsomag',
        MONTHLY_COST: 'Havi részlet',
        MONTHLY_FEE: 'Havi díj',
        COMMENT: 'megjegyzés',
        SUBSCRIPTION_PHONE_NUMBER: 'Előfizetői telefonszám',
        ACTIVATION_DATE: 'Aktiválás dátuma',
        IMEI_NUMBER: 'IMEI szám',
        SIM_NUMBER: 'Sim kártya száma',
        CUSTOMER_CODE: 'Vodafone ügyfélbiztonsági kód',
        SALES_PRICE: 'Fogy. ár',
        ACCESSORY: 'Tartozék',
        GENDER: 'Neme',
        //
        MOTHERS_BIRTH_NAME: 'Anya születési neve',
        BIRTH_NAME: 'Születési név',
        PLACE_OF_BIRTH: 'Születési hely',
        COUNTRY_OF_CITIZENSHIP: 'Állampolgarsag',
        IDENTITY_CARD_TYPE: 'Igazolvány típusa',
        HOUSE_NUMBER: 'Házszám',
        NAME_OF_PUBLIC_AREA: 'Közterület neve',
        NAME_OF_PUBLIC_SPACE: 'Közterület jellege',
        BUILDING: 'Épület',
        STAIRCASE: 'Lépcsőház',
        ID_CARD_NUMBER: 'Igazolvány száma (NAGY betűk és számok)',
        FLOOR: 'Emelet',
        DOOR: 'Ajtó',
        RESIDENCE_CARD: 'Lakcímkártya száma (NAGY betűk és számok)',
        MANUFACTURER_WARRANTY_TIME: 'Gyártói garancia ideje (hónap)',
        ADDRESS_DETAILS: 'Сímadatok',
        LINK_OPERATOR: 'Link az operátorhoz',
        SUMMARY_BONUS_AMOUNT: 'Összefoglaló bónuszösszeg',
        SUMMARY_TO_PAY: 'Összegzés a fizetésre',
        MONTHLY_DISCOUNT: 'Havi kedvezmény',
        IS_EMPLOYED: 'Alkalmazva van?',
        CONTRACT_PHONE_NUMBER: 'Előfizetés',
        SHOW_USE_ADDRESS_CHECKBOX: 'Tartózkodási hely megadása',
        COPY_FROM_PERMANENT_ADDRESS: 'Állandó lakcím másolása',
        VIEW: 'Elektronikus aláírás',
        INSURANCE: 'Garancia kiterjesztés',
        SCREENPROTECTOR: 'Screen Protector',
        NEW_PHONE_NUMBER: 'Új telefonszám',
      },
      VALIDATION: {
        INVALID: '{{name}} érvénytelen',
        REQUIRED: '{{name}} kötelezö',
        MIN_LENGTH: '{{name}} minimális karakter {{min}}',
        AGREEMENT_REQUIRED: 'A feltételek elfogadása kötelezö',
        NOT_FOUND: 'kérés {{name}} nincs találat',
        INVALID_LOGIN: 'Hibas bejelentkezés',
        REQUIRED_FIELD: 'Kötelező',
        MIN_LENGTH_FIELD: 'Minimális karakter :',
        MAX_LENGTH_FIELD: 'Maximális karakter:',
        INVALID_FIELD: 'érvénytelen',
        FORBIDDEN_SYMBOLS_FIELD: 'tiltott karaktereket tartalmaz',
        CONTRACT_PHONE_NUMBER_TO_BE_FILLEDOUT:
          'Kérjük, adja meg a szerződés telefonszámát.',
        VODAFONE_STATUS_SELECT_REASON: 'Kérjük, adja meg az elutasítás okát..',
      },
      LIST_TABLE: {
        TITLE: 'Ajánlat',
        ORDER_NUMBER: 'Rendelésszám',
        OPERATOR: {
          TITLE: 'Szolgáltato',
        },
        SALES: {
          TITLE: 'Értékesítő azonosítója',
        },
        STORE_ID: {
          TITLE: 'Áruház azonosítója',
        },
        DATE: {
          TITLE: 'Dátum',
        },
        GRABBED_BY: {
          TITLE: 'Feldolgoz',
        },
        STATUS: {
          TITLE: 'Status',
          ACTIVE: 'Nyitott',
          GRABBED: 'Folyamatban',
          HOT: 'Surgös',
        },
        STATE: {
          TITLE_FILTER: 'Szűrő',
          TITLE: 'Status',
          ALL: {
            FULL: 'Összes rendelés',
            SHORT: 'Összes',
          },
          NEW: {
            FULL: 'Új rendelések',
            SHORT: 'Új rendelések',
          },
          PENDING: {
            FULL: 'Rendelések feldolgozás alatt',
            SHORT: 'Feldolgozás',
          },
          COMPLETED: {
            FULL: 'Lezart rendelések',
            SHORT: 'Lezart',
          },
          CANCELLED: {
            FULL: 'Törölt rendelések',
            SHORT: 'Törölt',
          },
          RECEIVED: {
            FULL: 'Beérkezett rendelések (hitelminősítés függőben)',
            SHORT: 'Beérkezett',
          },
          CREDIT_CHECK_OK: {
            FULL: 'Ellenőrzés rendben (aláírás szukseges)',
            SHORT: 'Ellenőrzés rendben',
          },
          READY_FOR_SHIPMENT: {
            FULL: 'Szállitasra kész (bezárt)',
            SHORT: 'Bezárt',
          },
          PROPOSAL: {
            FULL: 'Javaslat',
            SHORT: 'Javaslat',
          },
          INFO_VIDEO: {
            FULL: 'Információs videók folyamatban',
            SHORT: 'Im Progress',
          },
        },
        FIRST_NAME: 'keresztnév',
        LAST_NAME: 'vezetéknév',
        PHONE_NUMBER: 'Telefonszám',
        EMAIL: 'Email',
        STORE: 'áruház',
        HEADQUARTER: 'Székhely',
        ACTIONS: {
          TITLE: 'akció',
          BTN_EDIT: 'Fogadja meg a megrendelést',
          BTN_DELETE: 'Rendelés törlése',
        },
      },
      LOG_TABLE: {
        NUMBER: 'szám',
        ACTOR: 'Felhasználo',
        CHANGES: {
          TITLE: 'módosítás',
          CARD_KEY: {
            OLD: 'régi',
            NEW: 'új',
          },
        },
        DATE_CHANGES: ' Dátum megváltoztatása',
        INSURANCESTATUS: 'Biztosítási állapot',
        CONTRACTPHONENUMBER: 'Szerződéses telefonszám',
        OPERATORSTATUS: 'Kezelőállapot',
        OPERATORREJECTIONREASON: 'Operátor elutasítási oka',
      },
      ORDER_DIALOG: {
        BTN_CANCEL: 'Megszakítja a megrendeléseket',
        BTN_NOT_CANCEL: 'Ne törölje',
        BTN_CLOSE: 'Mégse',
        BTN_SAVE: 'Mentés',
        REASON_CLOSING: {
          title: 'Kérjük, adjon rövid magyarázatot',
          COMMENT: 'Kérjük, írjon megjegyzést',
        },
        CONTRACT_DIALOG: {
          TITLE: 'Előfizetés módosítása',
        },
        PHONE_DIALOG: {
          TITLE: 'készülék cseréje',
          INPUT: 'Írja be a telefon nevét ',
        },
      },
    },
    CATALOG: {
      CURRENCY: 'Ft',
      SEARCH_PHONE: 'Telefon keresese',
      CLICK_OPERATOR_CALL:
        'Klicka på operatör vid försäljning av enbart abonnemang utan telefon',
      DETAILS_PHONE: {
        CONTRACT: {
          TITLE: 'Előfizetés típusa',
          TITLE_EDIT: 'Előfizetés módosítása',
          TYPE_CONTRACT: {
            NEW: 'Új ajánlat',
            EXTENSION: 'Előfizetés hosszabbítás',
          },
          DURATION_CONTRACT: {
            MONTH: 'hónap',
            oneYear: 'egy év',
            twoYears: 'Két év',
            threeYears: 'Három év',
          },
          TABLE: {
            TITLE_NAME: 'Tarifacsomag',
            MONTHLY_COST_CONTRACT: 'Előfizetés havidíj',
            MONTHLY_COST_DEVICE: 'Készülék havi részlet',
          },
        },
        SUMMARY_BLOCK: {
          MODIFY: 'módosít',
          PHONE: 'Keszulek',
          PAY_NOW: 'Fizetendö',
          MONTHS: 'hónap',
          MONTH: 'hó',
          CONTRACT: 'Tarifak',
          ADD_ON: 'Hozzaadas',
          TO_PAY: 'Fizetendö',
          TYPE_SUM_TO_PAY: 'Írja be a fizetendő összeget',
          ONE_TIME_FEE: 'Önerő',
          TOTAL_MONTHLY_COST: 'Részletfizetés és előfizetés havidíj/hó',
          MINIMUM_TOTAL_COST:
            'Szerzödeses idö összköltsége {{contractLength}} hónap',
            TELIA: {
                EXPLANATION: 'Effective interest rate (the total cost for partial payment)' +
                    ' with a representative example from 2020-12-01: Utilized account credit of SEK 10,000 to 0% credit interest (tied)' +
                    ' with a repayment period of 24 months, with 24 installments of SEK 416.66 / month gives an effective interest rate' +
                    ' of a total of 0% with e-invoice / e-mail invoice (SEK 0) or 11.15% with paper invoice (SEK 49 / pc). The total repayment ' +
                    'for an account credit of SEK 10,000 is thus SEK 10,000 if you have a digital invoice and SEK 11,127 if you have a paper invoice.'
            }
        },
        DATA_USAGE: {
          TITLE: 'Adathasználat (nem kötelező)',
          CALC: {
            TITLE: 'Napi adatmennyiség',
            video_streaming: 'Video',
            social_media: 'Közössegi media',
            music_streaming: 'Zene',
            web_surfing: 'Web Böngeszes ',
            HOURS_PER_DAY: 'óra / nap',
          },
          SIMPLE: {
            TITLE: 'Havi adatmennyiség',
            SELECT_UNLIMITED: 'korlátlan',
          },
          TOTAL_DATA: 'Teljes felhasznált adatmennyiség',
          UNLIMITED: 'Korlátlan',
        },
        device: {},
        PROVIDER: {
          TITLE: 'Vodafone',
          SORT_BY: {
            TITLE: 'rendezés',
            NAME: 'Szolgaltato neve',
            PRICE: 'ár',
            GB: 'GB',
          },
          SELECT: 'kiválaszt',
          SELECTED: 'kiválasztott',
          MORE_INFO: 'Több információ',
        },
        CARD: {
          TITLE: 'Összefoglaló',
          DISCOUNT: 'kedvezmény',
          TO_PAY: 'Önerő',
          TO_PAY_OWN_RESOURCES: 'Önerő fizetése',
          TYPE_SUM_TO_PAY: 'Írja be a fizetendő összeget',
          MAX_TO_PAY_ERROR: 'Maximális fizetendő összeg: {{toPay}}',
          TOTAL_PAY: 'Összesen fizetendö',
          CHECKOUT: 'tovább',
          ONE_MORE: 'Tovabbi termék hozzaadasa',
          REMOVE_DEVICE: 'törles',
          MONTHLY_DISCOUNT: 'Havi tarifakedvezmény',
          NO_FINANCE: 'Részletfizetés nélkül, egy összegben',
        },
        ADDITIONAL_DEVICE: {
          ADD_SUB_DEVICE: 'Sub eszköz hozzáadása',
        },
      },
      CHECKOUT: {
        TITLE: 'AJÁNLAT ÖSSZEÁLLÍTÁSA',
        TITLE_DONE: 'Megrendelés befejeződött',
        NEXT: 'tovább',
        SAVE_ORDER_PROPOSAL: 'Megrendelési javaslat mentése',
        FINISH_CHECKOUT: 'Csomagajánlat rögzítése',
        ADD_TO_CART: 'MCC Dokumentum',
        GET_PERSONAL_DATA: 'Információk beszerzése N/A',
        MODIFY_PERSONAL_DATA: 'Szerkesztés N/A',
        SAVE_PERSONAL_DATA: 'Mentés N/A',
        CONTRACT_TITLE: 'Tarifacsomagok',
        SEARCH_CONTRACT: 'Tarifacsomag keresése',
        TARIFF_TABLE: 'Tarifatábla',
        EXTRA: {
            TITLE: 'Extras',
            OFFERINGS: 'Extra offerings',
            USERS_DOES_NOT_SUPPORT: 'This subscription doesn’t support extra users',
            USERS: 'Extra Users',
            YOU_HAVE_EXISTING_SUBSCRIPTION:
                'You have existing subscription, would you like to include them in your family?',
            CURRENT: 'Current',
            AFTER_INCLUSION_IN_FAMILY: 'After inclusion in family',
            INCLUDE_IN_FAMILY: 'Include in family',
        },
        FINISH: {
          CUSTOMER_INFO: 'Személyes adatok',
          SUMMARY_INFO: 'A megrendelés összefoglalása :',
          CONTRACT_ACTIVATION_INFO: 'Készülék információk :',
          SIM_CONTRACT_INFO: 'Aktiválás és számválasztás :',
        },
        OVERVIEW: {
          ADDITIONAL_PRODUCTS_ARE_AVAILABLE_FOR_SELECTED_CONTRACT:
            'Az alabbi kiegiszitök a választott előfizetéshez érhetők el',
          INSURANCES_AVAILABLE_FOR_SELECTED_CONTRACT:
            'Garancia Plusz garancia kiterjesztések a kiválasztott készülékhez',
          NO_AVAILABLE_FOR_SELECTED_CONTRACT:
            'Nincsenek választható kiegészítések ehhez az előfizetéshez',
          MONTH: 'hó',
          PERSONS: 'személyek N/A',
          SELECT: 'kiválaszt',
          UNSELECT: 'módosítás',
          SELECTED: 'kiválasztott',
          TITLE: 'Garancia kiterjesztés és kellékek',
          INSURANCE: 'ClearPlex fólia kerül a készülékre a G+ mellett',
          TABLE: {
            ARTICLE: 'Termek',
            NAME: 'név',
            QTY: 'Mennyiseg',
            PRICE: 'ár',
          },
          INTERNAL_INFO: {
            TITLE: 'Belső információ',
          },
        },
        OFFERING_SUMMARY: {
          TITLE: 'Ajánlat összefoglaló',
        },
        SUCCESS: {
          MESSAGE: 'Rendelés megtörtént. Rendelési azonosító: ',
          NEXT: 'Vissza a keresöbe',
          BACK: 'Maradj itt',
        },
        CHOOSE_NUMBER_BLOCK: {
          YOUR_NUMBER: 'Jelenlegi telefonszám',
          GET_NEW_NUMBER: 'Új telefonszám',
          ASSIGNED_NUMBER_BY_PROVIDER:
            'A szolgáltató egy új telefonszámot fog adni',
          KEEP_EXISTING_NUMBER: 'számhordozas',
          PORTING_NUMBER_NOTICE:
            'Szolgaltato váltás esetén a számhordozás űgymenetet az új szolgáltato végzi.',
          MY_EXISTING_NUMBER: 'A jelenlegi telefonszám - 46712345678',
          MOVE_NUMBER: 'Szamhordozas',
          YES: 'Igen',
        },
        PRICE: {
            TITLE: 'Ár',
        },
        DETAILS: {
          TITLE: 'Személyes adatok',
          TITLE_CUSTOMER_INFORMATION: 'Ügyfél adatok',
          TITLE_BANK_ACCOUNT: 'Bankszámlaszám',
          TITLE_SUBSCRIPTION: 'Előfizetési információk ',
          SUBSCRIPTION: 'Számhordozas',
            BANK_ID: {
                SKIP: 'Skip BankID verification',
                PREFERRED_METHOD: 'Preferred identification method:'
            },
          FORM: {
            CUSTOMERID: 'Ügyfél-azonosító',
            ADDRESSCARDNUMBER: 'Lakcímkártya',
            BIRTHDATE: 'Születési dátum',
            MOTHERNAME: 'Anyja neve',
            FIRSTNAME: 'keresztnév',
            LASTNAME: 'vezetéknév',
            ADDRESS: 'cím',
            ZIPCODE: 'irányítószám',
            CITY: 'város',
            PHONENUMBER: 'Telefonszám',
            EMAIL: 'Email',
            ACCOUNTBANK: 'bankszámla szam',
            BANKNAME: 'szamlavezetö bank neve',
            IBAN: 'IBAN',
            SUBSCRIPTIONPHONENUMBER: 'Elöfizetö Telefonszáma',
            PROVIDER: 'Szolgaltato',
            SSN: 'személyi igazolvany szama',
          },
          VALIDATION: {
            INVALID: '{{name}} érvénytelen',
            REQUIRED: '{{name}} kötelező',
            MIN_LENGTH: '{{name}} minimális karakter {{min}}',
            AGREEMENT_REQUIRED: 'A feltételek elfogadása szükséges',
            NOT_FOUND: 'kérés {{name}} nincs találat',
            INVALID_LOGIN: '(check above) Helytelen bejelentkezési információ',
            REQUIRED_FIELD: 'Kötelező ',
            MIN_LENGTH_FIELD: 'Minimális karakter:',
            MAX_LENGTH_FIELD: 'maximális karakter:',
            INVALID_FIELD: 'érvénytelen',
            FORBIDDEN_SYMBOLS_FIELD: 'tiltott karaktereket tartalmaz',
          },
          DIALOG: {
              BANK_ID_VALIDATION: {
                  WAITING_CUSTOMER: 'Waiting for manager BankID verification',
                  WAITING: 'Waiting for manager BankID verification',
                  TIMEOUT: 'BankID timeout p.g.a.',
                  FAILED: 'BankID verification failed',
                  TELIA: 'Enter your Tholbox user id',
                  TELE2: 'Scan qr to provide your Vendo user id',
              }
          },
        },
        ACTIVATION: {
          TITLE: 'Készülék és SIM kártya kiválasztása',
          IMEI_NUMBER: 'IMEI szám',
          SIM_NUMBER: 'Sim kártya száma',
          SUBSCRIPTION_NUMBER: 'Előfizetöi telefonszám',
          AGREEMENT_NUMBER: 'szerződés száma',
            MANUAL: 'Lägg order manuellt',
            MANUAL_CONFIRM: 'Kryssa i nedan om du måste lägga order manuellt i Vendo (Ej API order)',
            TELIA_MANUAL_CONFIRM: 'Kryssa i nedan om du måste lägga order manuellt i Tholbox (Ej API order)',
            USER_INFORMED: 'I have informed the customer about the following:',
            TELIA: {
                CUSTOMER_AGREEMENT_TEXT: 'If necessary to complete the purchase, a credit report will be obtained'
            }
        },
        DONE: {
          TITLE: 'Összefoglaló',
        },
      },
    },
    STAFF_BONUS: {
        TITLE: 'Staff bonus ',
        EXPORT_ACTION: 'Export to xls',
        FILTERS: {
            HEADQUARTER: 'Headquarter',
            START_DATE: 'Start date',
            END_DATE: 'End date',
            GROUP_BY: 'Group by',
            STORE: 'Store',
            EMPLOYEE: 'Employee',
        },
        TABLE: {
            COLUMN_NAMES: {
                DATE: 'Date',
                NAME: 'Name',
                STORE: 'Store',
                NUMBER_OF_ORDERS: 'Number of orders',
                TOTAL: 'Total',
                BONUS: 'Bonus',
            }
        }
    },
    CAMPAIGNS: {
          LIST: {
              TITLE: 'Campaigns list',
              FILTERS: {
                  HEADQUARTER: 'Headquarter',
                  NAME: 'Campaign name filter',
                  STATUS: 'Status filter',
                  STORE: 'Store',
              },
              TABLE: {
                  COLUMN_NAMES: {
                      NAME: 'Campaign name',
                      START_DATE: 'Start date',
                      END_DATE: 'End date',
                      TYPE: 'Type',
                      STORES: 'Stores',
                      STATUS: 'Status',
                      ACTIONS: 'Actions'
                  },
                  PAGINATOR: {}
              },
              ACTIONS: {
                  ADD: 'Add new campaign',
                  PAUSE: 'Pause',
                  UNPAUSE: 'Resume',
                  APPROVE: 'Approve',
                  DUPLICATE: 'Duplicate',
                  DELETE: 'Delete',
              },
          },
          EDIT: {
              TITLE: {
                  CREATE: 'Create campaign',
                  EDIT: 'Edit campaign',
              },
              ACTIONS: {
                  UPLOAD_PDF: 'Upload campaign PDF',
                  SAVE: 'Save',
              },
              HEADQUARTER: 'Select headquarter',
              STORE: 'Select store',
              NAME: 'Campaign name',
              START_DATE: 'Start date',
              END_DATE: 'End date',
              DESCRIPTION: 'Campaign description (optional)',
              TYPE: {
                  TITLE: 'Type',
                  DISCOUNT: 'General discount',
                  BUNDLING: 'Bundling',
              },
              CRITERIA: {
                  TITLE: 'Criteria',
                  PRODUCT_GROUP: 'Product group',
                  DEVICES: 'Devices',
                  DEVICE_VALUE: 'Device value more than',
                  PROVIDER: 'Provider',
                  CONTRACT: 'Contract',
              },
              DISCOUNT: {
                  TYPE: 'Discount type (% or value)',
                  AMOUNT: 'Discount amount',
              },
              BUNDLINGS: {
                  TITLE: 'Select product',
                  PRODUCT: 'Bundling product',
                  PRODUCTS_ALLOWED: '№ of products allowed',
                  VALUE_UP_TO: 'Products for a value up to',
              },
              CAN_BE_COMBINED: 'Can be combined with other campaigns'
          }
      },
    HEADQUARTERS: {
          TITLE: {
              NEW: 'Create headquarter',
              EDIT: 'Edit headquarter'
          },
          EDIT: {
              ACTIONS: {
                  BACK: 'Back',
                  RESET: 'Reset',
                  SAVE: 'Save',
              },
              BASIC_INFO: {
                  LABEL: 'Basic info',
                  NAME: {
                      EDIT: 'Enter name',
                      TITLE: 'Name',
                      HINT: `Please enter Name`,
                      ERROR: {
                          REQUIRED: `Name is required`,
                          PATTERN: `Name contains invalid characters`,
                      }
                  },
              }
          },
      },
    ITEMS_PER_PAGE_LABEL: 'Találat oldalanként',
    CUSTOMER_DATA: 'Ügyfél adatok',
    DISCOUNT_CONTRACT: 'Szerzödési kedvezmény',
    TO_PAY_FLAT: 'Önerő',
    MONTHLY_COST_FLAT: 'Havi részlet',
    GRABBED_FLAT: 'Megnyitás',
    SIM_CARD_ACTIVATE: 'SIM Kártya ellenörzés ',
    SIM_CARD_ACTIVATED: 'Sim kártya aktiválva',
    CONTRACTS_FLAT: 'Szerzödések',
    INFORMATION_LEAFLETS: 'Tájékoztatók',
    ORDER_STATUS_VODAFONE_NEW: 'Új bírálat',
    NEW: 'Új bírálat',
    ORDER_STATUS_VODAFONE_SUCCESSFUL_REVIEW: 'Sikeres Vodafone bírálat ',
    SUCCESSFUL_REVIEW: 'Sikeres Vodafone bírálat ',
    ORDER_STATUS_VODAFONE_UNSUCCESSFUL_REVIEW: 'Sikertelen Vodafone bírálat',
    UNSUCCESSFUL_REVIEW: 'Sikertelen Vodafone bírálat',
    ORDER_STATUS_VODAFONE_INCORRECT_DETAILS: 'Hibás adatok',
    INCORRECT_DETAILS: 'Hibás adatok',
    ORDER_STATUS_BUDAPEST_BANK_NEW: 'Új kérelem',
    ORDER_STATUS_BUDAPEST_BANK_CREDIT_RAITING_IN_PROGRESS:
      'Hitelbírálat folyamatban',
    ORDER_STATUS_BUDAPEST_BANK_SUCCESSFUL_LOAN_REQUEST: 'Sikeres hiteligénylés',
    ORDER_STATUS_BUDAPEST_BANK_UNSUCCESSFUL_LOAN_REQUEST_OFFER_CANCELLATION:
      'Sikertelen hiteligénylés - Ajánlat törlés',
    ORDER_STATUS_BUDAPEST_BANK_UNSUCCESSFUL_LOAN_REQUEST_CASH_PURCHASE:
      'Sikertelen hiteligénylés - Készpénzes vásárlás',
    ORDER_STATUS_BUDAPEST_BANK_NO_FINANCING: 'Nem igényelt',
    ORDER_STATUS_TO_HANDLE: 'To Handle',
    ORDER_STATUS_DELETED_OFFER: 'Törölt ajánlat',
    ORDER_STATUS_INCORRECT_DETAILS: 'Hibás adatok',
    ORDER_STATUS_OFFERING_CANCELLED: 'Ajánlat felfüggesztve',
    CHECK_STATUS: 'Státusz ellenőrzés',
    CHECK_STATUS_TOOLTIP:
      '•Ellenőrizd, hogy a+n behívásra váró vásárló minden\n' +
      ' igényelt szolgáltatás és dokumentuma rendelkezésre áll (Vodafone, G+, BB, MM)!',
    INFO_2_TOOLTIP:
      "•Kattints az „MCC+n' dokumentum létrehozása” gombra,\n" +
      ' majd nyomtasd ki a rendszerből!',
    INFO_3_TOOLTIP:
      '•Hívd be a vásárlót a Linistry\n' +
      ' segítségével pulthoz és azonosítsd!',
    INFO_4_TOOLTIP:
      '•GDPR nyilatkozat és Vodafone szolgáltatói\n' +
      ' szerződés ELEKTRONIKUS aláírása',
    INFO_5_TOOLTIP:
      '•Kasszázd a szükséges tételeket\n ' +
      '(átvételi bizonylat, Garancia Plusz)!',
    INFO_6_TOOLTIP:
      '•Ellenőrizd a számát, majd aktiváld az \n ' +
      ' átadott SIM kártyát a vásárló részére!',
    INFO_7_TOOLTIP:
      '•Érvényesítsd a termék(ek) jótállási jegyét,\n' +
      ' kötvényesítsd a Garancia Plusz-t és add ki a terméke(ke)t a vásárlónak!',
    INFO_8_TOOLTIP:
      '•Ellenőrizd hogy a termék és a\n' +
      ' dokumentumok aláírásra, átadásra kerültek!',
    SIGN_OUT: 'Kijelentkezés',
    PER_MONTH_TOP: 'Havidíj',
    PER_MONTH_MIDDLE: 'Havi részlet',
    CARD_TITLE_INFORMATION: ' Információ!',
    CLOSE_ORDER_TITLE: 'Zárja be a rendet és aktiválja a biztosítást',
    ORDER_CLOSE_BUTTON: 'Rendelés kész (zárva)',
    LIMIT_ACCESSORY_MSG:
      'A csomagajánlat összesen maximum 5 db tartozékot és Okospult szolgáltatást tartalmazhat',
    deposit: 'Depozit',
  },
};
