import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { fromEvent, merge, Observable, Subject, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AppState } from '../../../../../../../../core/reducers';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../../../../../core/_base/crud';
import {
  HeadquarterEmployeeModel,
  HeadquarterEmployeeOnServerCreated,
  HeadquarterEmployeesDataSource,
  HeadquarterEmployeesPageRequested,
  HeadquarterEmployeeUpdated,
  ManyHeadquarterEmployeesDeleted,
  OneHeadquarterEmployeeDeleted,
  selectLastCreatedHeadquarterEmployeeId,
} from '../../../../../../../../core/headquarter';
import { HeadquarterEmployeeEditDialogComponent } from '../employee-edit/employee-edit-dialog.component';

@Component({
  selector: 'kt-headquarter-employees-list',
  templateUrl: './employees-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadquarterEmployeesListComponent implements OnInit, OnDestroy {
  @Input() headquarterId$: Observable<number>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
  private destroyStream$ = new Subject<void>();
  headquarterId: number;
  dataSource: HeadquarterEmployeesDataSource;
  displayedColumns = ['email', 'actions'];
  selection = new SelectionModel<HeadquarterEmployeeModel>(true, []);
  headquarterEmployeesResult: HeadquarterEmployeeModel[] = [];

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService,
  ) {}

  ngOnInit() {
    this.sort.sortChange
      .pipe(takeUntil(this.destroyStream$))
      .subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.loadEmployeesList();
        }),
      )
      .subscribe();

    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        takeUntil(this.destroyStream$),
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadEmployeesList();
        }),
      )
      .subscribe();

    this.dataSource = new HeadquarterEmployeesDataSource(this.store);
    this.dataSource.entitySubject
      .pipe(takeUntil(this.destroyStream$))
      .subscribe((res) => (this.headquarterEmployeesResult = res));
    this.headquarterId$
      .pipe(takeUntil(this.destroyStream$))
      .subscribe((res) => {
        if (!res) {
          return;
        }

        this.headquarterId = res;
        this.loadEmployeesList();
      });
  }

  ngOnDestroy() {
    this.destroyStream$.next();
    this.destroyStream$.complete();
  }

  loadEmployeesList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
    );
    this.store.dispatch(
      new HeadquarterEmployeesPageRequested({
        page: queryParams,
        headquarterId: this.headquarterId,
      }),
    );
  }

  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;

    filter._specificationName = searchText;
    filter.value = searchText;
    return filter;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.headquarterEmployeesResult.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.headquarterEmployeesResult.forEach((row) =>
        this.selection.select(row),
      );
    }
  }

  deleteEmployee(_item: HeadquarterEmployeeModel) {
    const _title: string = 'Employee Delete';
    const _description: string =
      'Are you sure to permanently delete this employee?';
    const _waitDesciption: string = 'Employee is deleting...';
    const _deleteMessage = `Employee has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyStream$))
      .subscribe((res) => {
        if (!res) {
          return;
        }

        this.store.dispatch(
          new OneHeadquarterEmployeeDeleted({
            employeeId: _item.id,
            headquarterId: this.headquarterId,
          }),
        );
        this.layoutUtilsService.showActionNotification(
          _deleteMessage,
          MessageType.Delete,
        );
        this.loadEmployeesList();
      });
  }

  deleteEmployees() {
    const _title: string = 'Employees Delete';
    const _description: string =
      'Are you sure to permanently delete selected employees?';
    const _waitDesciption: string = 'Employees are deleting...';
    const _deleteMessage = 'Selected employees have been deleted';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyStream$))
      .subscribe((res) => {
        if (!res) {
          return;
        }

        const length = this.selection.selected.length;
        const idsForDeletion: number[] = [];
        for (let i = 0; i < length; i++) {
          idsForDeletion.push(this.selection.selected[i].id);
        }
        this.store.dispatch(
          new ManyHeadquarterEmployeesDeleted({ ids: idsForDeletion }),
        );
        this.layoutUtilsService.showActionNotification(
          _deleteMessage,
          MessageType.Delete,
        );
        this.selection.clear();
      });
  }

  fetchEmployees() {
    const messages = [];
    this.selection.selected.forEach((elem) => {
      messages.push({
        text: `${elem.email}`,
        id: elem.id,
      });
    });
    this.layoutUtilsService.fetchElements(messages);
  }

  addEmployee() {
    let newSpec = new HeadquarterEmployeeModel();
    newSpec.clear(this.headquarterId);
    const dialogRef = this.dialog.open(HeadquarterEmployeeEditDialogComponent, {
      data: {
        employeeId: undefined,
        role: 'employee',
        isNew: true,
        headquarterId: this.headquarterId,
      },
      width: '450px',
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyStream$))
      .subscribe((res) => {
        if (res.isUpdated) {
          this.store.dispatch(
            new HeadquarterEmployeeOnServerCreated({
              employeeId: res.employeeId,
              headquarterId: this.headquarterId,
              role: res.role,
            }),
          );
          this.store
            .pipe(select(selectLastCreatedHeadquarterEmployeeId))
            .subscribe((result) => {
              if (!result) {
                return;
              }

              const saveMessage = `Employee has been created`;
              this.layoutUtilsService.showActionNotification(
                saveMessage,
                MessageType.Create,
                10000,
                true,
                false,
              );
            });
        }
      });
  }

  editEmployee(item: HeadquarterEmployeeModel) {
    const _item = Object.assign({}, item);
    const dialogRef = this.dialog.open(HeadquarterEmployeeEditDialogComponent, {
      data: {
        employeeId: _item.id,
        role: 'employee',
        isNew: false,
      },
      width: '450px',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.isUpdated) {
        const updateHeadquarterEmployee: Update<HeadquarterEmployeeModel> = {
          id: _item.id,
          changes: _item,
        };
        this.store.dispatch(
          new HeadquarterEmployeeUpdated({
            partialHeadquarterEmployee: updateHeadquarterEmployee,
            headquarterEmployee: _item,
          }),
        );
        const saveMessage = `Employee has been updated`;
        this.layoutUtilsService.showActionNotification(
          saveMessage,
          MessageType.Update,
          10000,
          true,
          true,
        );
      }
    });
  }
}
