// Angular
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState } from '../../reducers';
import { isLoggedIn } from '../_selectors/auth.selectors';
import { NgxPermissionsService } from 'ngx-permissions';
import {LocalStorageService} from '../../services/local-storage.service';

@Injectable()
export class WebhallenGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private permissionService: NgxPermissionsService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.store.pipe(
      select(isLoggedIn),
      tap(() => {
        this.permissionService.permissions$.subscribe((permissions) => {
          return true;
        });
      }),
    );
  }
}
