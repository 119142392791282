import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { DevicesService, DeviceModel } from '../../../../core/catalog';
import { Router } from '@angular/router';
import {LocalStorageService} from "../../../../core/services/local-storage.service";

@Component({
  selector: 'kt-search-phone',
  templateUrl: './search-phone.component.html',
  styleUrls: ['./search-phone.component.scss'],
  providers: [{ provide: MAT_DIALOG_DATA, useValue: [] }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPhoneComponent implements OnInit, OnDestroy {
  @Input() additionalDevicePopup = false;
  @Output() deviceSelectedChange = new EventEmitter<DeviceModel>();
  private destroyStream$ = new Subject<void>();
  DEVICELIST: DeviceModel[];
  searchForm: UntypedFormGroup;
  searching = false;
  isAdmin: boolean = false;
  selectedHeadquarter: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SearchPhoneComponent>,
    private _fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private devicesService: DevicesService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.selectedHeadquarter = LocalStorageService.get('headquarter').id;
    this.createUserForm();
  }

  ngOnDestroy() {
    this.destroyStream$.next();
    this.destroyStream$.complete();
  }

  createUserForm() {
    this.searchForm = this._fb.group({
      name: ['', Validators.required],
    });

    this.searchForm
      .get('name')
      .valueChanges.pipe(
        takeUntil(this.destroyStream$),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.searching = true)),
        switchMap((value: string) => {
          const query = value + `&headquarterId=${this.selectedHeadquarter}`;
          return this.devicesService.autocompleteDevices(query).pipe(
            takeUntil(this.destroyStream$),
            catchError(() => of([])),
          );
        }),
        tap(() => (this.searching = false)),
      )
      .subscribe((res: DeviceModel[]) => {
        this.DEVICELIST = res.map((item) => new DeviceModel(item));

        if (this.searchForm.get('name').invalid) {
          this.DEVICELIST = [];
        }

        this.cdr.detectChanges();
      });
  }

  close(device: DeviceModel) {
    this.data = device.id;

    if (this.additionalDevicePopup) {
      this.deviceSelectedChange.emit(device);
      this.searchForm.reset();
    } else {
      this.router.navigate(['/catalog/details/', device.id]);
      this.dialogRef.close(this.data);
    }
  }

  clearSearchForm() {
    this.searchForm.reset();
    this.cdr.detectChanges();
  }
}
