import { Component, HostBinding, OnInit, Input } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslationService } from '../../../../../core/_base/layout';

interface LanguageFlag {
  lang: string;
  name: string;
  flag: string;
  active?: boolean;
}

@Component({
  selector: 'kt-language-selector',
  templateUrl: './language-selector.component.html',
})
export class LanguageSelectorComponent implements OnInit {
  @HostBinding('class') classes = '';
  @Input() iconType: '' | 'brand';

  language: LanguageFlag;
  languages: LanguageFlag[] = [
    {
      lang: 'en',
      name: 'English',
      flag: './assets/media/flags/012-uk.svg',
    },
    {
      lang: 'se',
      name: 'Swedish',
      flag: './assets/media/flags/swedish.svg',
    },
    // {
    //   lang: 'hu',
    //   name: 'Hungarian',
    //   flag: './assets/media/flags/hungary.svg',
    // },
  ];

  constructor(
    private translationService: TranslationService,
    private router: Router,
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.setSelectedLanguage();
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event) => {
        this.setSelectedLanguage();
      });
  }

  setLanguage(lang) {
    this.languages.forEach((language: LanguageFlag) => {
      if (language.lang === lang) {
        language.active = true;
        this.language = language;
      } else {
        language.active = false;
      }
    });
    this.translationService.setLanguage(lang);
  }

  setSelectedLanguage(): any {
    this.setLanguage(this.translationService.getSelectedLanguage());
  }
}
