import { Subject } from 'rxjs';
// Angular
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router, NavigationStart } from '@angular/router';
// Layout
import { LayoutConfigService, SplashScreenService } from './core/_base/layout';
// Layout
import { TranslationService } from './core/_base/layout';
// language list
import { locale as enLang } from './core/_config/i18n/en';
import { locale as seLang } from './core/_config/i18n/se';
import { locale as huLang } from './core/_config/i18n/hu';
import { environment } from '../environments/environment';
import { AuthService, isLoggedIn, Login, Logout } from './core/auth';
import { AppState } from './core/reducers';
import { select, Store } from '@ngrx/store';
import * as LogRocket from 'logrocket';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivityMenuComponent } from './views/partials/layout/activity-menu/activity-menu.component';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil, tap } from 'rxjs/operators';
import { HOUR } from './core/utils/constants/time.constants';
import {LocalStorageService} from "./core/services/local-storage.service";
import { LoginPopupComponent } from './views/partials/layout';

const helper = new JwtHelperService();

// const TOKEN_REFRESH_TIME = 10000;
const TOKEN_REFRESH_TIME = 0.5 * HOUR;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  // Public properties
  title = 'Verticula';
  loader: boolean;
  userIsLoggedIn = false;
  private componentDestroyed$ = new Subject();
  private isTimerActivityInProgress: boolean = false;
  private currentTimer;
  private tokenInterval;

  // private activityTime = 60 * 1000 * 15;
  private activityTime = 1000 * 600;

  constructor(
    private translationService: TranslationService,
    private router: Router,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService,
    private authService: AuthService,
    private store: Store<AppState>,
    private dialog: MatDialog,
  ) {
    this.translationService.loadTranslations(enLang, seLang, huLang);
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove() {
    if (this.isTimerActivityInProgress) {
      this.updateTimer();
    }
  }

  ngOnInit(): void {
    this.setAppLanguage();

    this.store
      .pipe(
        takeUntil(this.componentDestroyed$),
        select(isLoggedIn),
        tap((loggedIn) => (this.userIsLoggedIn = loggedIn)),
      )
      .subscribe();

    if (LocalStorageService.get(environment.authTokenKey)) {
      let authToken = LocalStorageService.get(environment.authTokenKey);
      let refreshToken = LocalStorageService.get('refresh');
      if (helper.isTokenExpired(authToken)) {
        if (helper.isTokenExpired(refreshToken)) {
          this.store.dispatch(new Logout());
        } else {
          this.updateTokens();
        }
      } else {
        this.isTimerActivityInProgress = true;
        this.startTimer();
      }
    }

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (!event.url.includes('login')) {
          if (
            !helper.isTokenExpired(
              LocalStorageService.get(environment.authTokenKey),
            )
          ) {
            if (!helper.isTokenExpired(LocalStorageService.get('refresh'))) {
              if (this.isTimerActivityInProgress) {
                this.updateTimer();
              } else {
                this.startTimer();
                // this.updateTimer();
              }
            }
          }
        }
      }
    });
    // enable/disable loader
    let autologinIssue = setTimeout((_) => {
      LogRocket.track('Autologin failed');
    }, 5000);
    if (!LocalStorageService.get(environment.authTokenKey)) {
      this.authService.getTokenByIp().subscribe(
        (res: any) => {
          if (res && res.token) {
            LocalStorageService.save(environment.authTokenKey, res.token);
              LocalStorageService.save('refresh', res.refreshToken);
            this.store.dispatch(
              new Login({
                authToken: res.token,
                refreshToken: res.refreshToken,
              }),
            );
            window['storeId'] = res.storeId;
            this.router.navigateByUrl('catalog/list');
            clearTimeout(autologinIssue);
          }
        },
        () => {
          let configuration = LocalStorageService.get('headquarter');
          if (configuration && configuration.checkCatalogVisibilityByIp) {
            let autologinCatalogIssue = setTimeout((_) => {
              LogRocket.track('Catalog preview failed');
            }, 5000);
            this.authService.catalogPreviewCheck().subscribe((res: any) => {
              clearTimeout(autologinCatalogIssue);

              if (res.allow) {
                this.router.navigate(['/catalog/list']);
              }
            });
          }
        },
      );
    }

    this.loader = this.layoutConfigService.getConfig('loader.enabled');

    this.router.events
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.splashScreenService.hide();
          window.scrollTo(0, 0);
        }
      });
  }

  private startTokenInterval() {
    this.tokenInterval = setInterval((_) => {
      const refreshToken = LocalStorageService.get('refresh');

      if (!this.userIsLoggedIn || !refreshToken || !refreshToken.length) {
        this.stopTimer();
        return;
      }

      this.authService.getNewTokens(refreshToken).subscribe((res) => {
        LocalStorageService.save(environment.authTokenKey, res.token);
        LocalStorageService.save('refresh', res.refreshToken);
      });
    }, TOKEN_REFRESH_TIME);
  }

  private stopTimer(): void {
    this.isTimerActivityInProgress = false;
    clearInterval(this.tokenInterval);
    clearTimeout(this.currentTimer);
  }

  private startTimer() {
    this.isTimerActivityInProgress = true;
    this.currentTimer = setTimeout((_) => {
      this.showActivityWindow();
    }, this.activityTime);

    this.startTokenInterval();
  }

  private updateTimer() {
    clearTimeout(this.currentTimer);
    this.currentTimer = setTimeout((_) => {
      this.showActivityWindow();
    }, this.activityTime);
  }

  private showActivityWindow() {
    this.stopTimer();

    const dialog = this.dialog.open(ActivityMenuComponent, {
      width: '400px',
      disableClose: true,
      data: { action: '' },
    });

    dialog.afterClosed().subscribe((res) => {
      if (res.action === 'confirm') {
        this.isTimerActivityInProgress = true;
        this.updateTokens();
      } else if (res.action === 'auto-logout') {
        this.showLoginPopup()
        this.isTimerActivityInProgress = false;
        clearTimeout(this.currentTimer);
        LocalStorageService.remove(environment.authTokenKey);
        LocalStorageService.remove('refresh');
      } else if (res.action === 'logout') {
          this.isTimerActivityInProgress = false;
          clearTimeout(this.currentTimer);
          this.store.dispatch(new Logout());
      }
    });
  }

  private showLoginPopup() {
      const dialogRef = this.dialog.open(LoginPopupComponent, {
          width: '400px',
          disableClose: true,
          data: {
              successLogin: false,
          },
      });

      dialogRef.afterClosed().subscribe((res) => {
          if (res && res.successLogin) {
              this.isTimerActivityInProgress = true;
              this.updateTimer();
              this.store.dispatch(
                  new Login({
                      authToken: res.token,
                      refreshToken: res.refresh,
                  }),
              );
          } else {
              this.isTimerActivityInProgress = false;
              clearTimeout(this.currentTimer);
              this.store.dispatch(new Logout());
          }
      });
  }

  private updateTokens() {
    this.authService.getNewTokens(LocalStorageService.get('refresh')).subscribe(
      (res) => {
          LocalStorageService.save(environment.authTokenKey, res.token);
          LocalStorageService.save('refresh', res.refreshToken);
        // start timer function
        if (this.isTimerActivityInProgress) {
          this.updateTimer();
        } else {
          this.startTimer();
        }
      },
      () => {
        clearInterval(this.tokenInterval);
      },
    );
  }

  private setAppLanguage(): void {
    const domainPrefix = new URL(window.location.origin).host.split('.')[0];
    const appLang =
      domainPrefix === 'mmhu' ? 'hu' : ['mmse','se'].includes(domainPrefix)  ? 'se' : 'en';
    console.log(domainPrefix);
    console.log(['mmse','se'].includes(domainPrefix));
    this.translationService.setLanguage(appLang);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
