import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import {
  AuthNoticeService,
  AuthService,
  isLoggedIn,
  Login,
} from '../../../../core/auth';
import * as LogRocket from 'logrocket';
import {LocalStorageService} from "../../../../core/services/local-storage.service";
import {MatDialog} from "@angular/material/dialog";
import {StoreChangingDialogComponent} from "../../../partials/components/store-changing-dialog/store-changing-dialog.component";

@Component({
  selector: 'kt-login',
  templateUrl: './login.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: UntypedFormGroup;
  loading = false;
  errors: any = [];
  private unsubscribe: Subject<any>;
  private returnUrl: any;
  isAdmin: boolean;

  constructor(
    private router: Router,
    private auth: AuthService,
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    this.unsubscribe = new Subject();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params['returnUrl'] || '/';
    });

    this.store
      .pipe(
        select(isLoggedIn),
        tap((loggedIn) => {
          if (loggedIn) {
            this.router.navigateByUrl(this.returnUrl);
          }
        }),
      )
      .subscribe();

    this.initLoginForm();
  }

  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  initLoginForm() {
    this.loginForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ]),
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
      ],
    });
  }

  submit() {
    this.errors = [];
    const controls = this.loginForm.controls;
    if (this.loginForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched(),
      );
      return;
    }

    this.loading = true;

    const authData = {
      email: controls['email'].value,
      password: controls['password'].value,
    };

    let loginIssue = setTimeout((_) => {
      LogRocket.track('Login failed');
    }, 5000);

    this.auth
      .login(authData.email, authData.password)
      .pipe(
        tap((response) => {
          if (response) {
            clearTimeout(loginIssue);
            LocalStorageService.save('headquarter', response.user.headquarter);
            console.warn('LoginComponent.login() ', {
              authToken: response.token,
            });

            this.isAdmin = response.user.roles.some(({name}) => name === 'ROLE_SUPER_ADMIN');

            if (response.user.headquarter.storeChangingAllowed && !this.isAdmin) {
                const dialogRef = this.dialog.open(StoreChangingDialogComponent, {
                    disableClose: true,
                    maxWidth: '440px',
                    data: {
                        headquarterId: response.user.headquarterId,
                        selectedStoreId: response.user.storeId
                    },
                });
                dialogRef.afterClosed().subscribe((res) => {
                    this.store.dispatch(
                      new Login({
                        authToken: response.token,
                        refreshToken: response.refreshToken,
                      }),
                    );
                  });
            }
            this.store.dispatch(
              new Login({
                authToken: response.token,
                refreshToken: response.refreshToken,
              }),
            );
            if (this.returnUrl === '/') {
              this.router.navigateByUrl('/catalog/list');
            } else {
              this.router.navigateByUrl(this.returnUrl);
            }
          }
        }),
        catchError((res) => {
            console.log(res)
          this.errors = [res.error.error];
          this.authNoticeService.setNotice(
            this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'),
            'danger',
          );
          return of();
        }),
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.loading = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.loginForm.controls[controlName];
    if (!control) {
      return false;
    }

    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }
}
